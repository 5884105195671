import React from "react";
import PropTypes from "prop-types";
import MediaGalleryProvider from "@paragraphs/media-gallery/store/media-gallery-provider";
import VideoSlider from "@paragraphs/video-slider/video-slider";

const ParagraphVideoSlider = ({ content }) => {
  return(
    <MediaGalleryProvider>
      <VideoSlider
        content={content}
      />
    </MediaGalleryProvider>
  );
}

ParagraphVideoSlider.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default ParagraphVideoSlider;