import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";

const TeaserAct = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const dispatch = useDispatch(),
    location = useLocation();

  return (
    <article
      className="node node-teaser teaser-act col-16 col-md-4"
      data-index={index}
    >
      <div className="mask-wrapper">
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location.pathname}
        />
        <Link
          onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
          className="flex-wrap"
          to={item.url?.path || item.path.alias}
        >
          {item.fieldImage && (
            <Image
              data={item.fieldImage.fieldMediaImage}
              nodeTitle={item.title}
            />
          )}
          <div className="hover-infos">
            <h3 className="name">{item.title}</h3>
          </div>
        </Link>
      </div>
    </article>
  );
};

export const teaserActPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserAct.propTypes = {
  item: teaserActPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserAct;
