import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {authenticationLoginAction} from "../../intern/redux-authentication";

const AnchorLink = ({link}) => {

  useEffect(() => {
    if (
      link &&
      window.location.hash &&
      window.location.hash.replace("#", "") === link
    ) {
      // get Anchor position and scroll to it
      let anchorLink = document.getElementById(
        window.location.hash.replace("#", "")
      );
      if (anchorLink) {
        setTimeout(() => {
          anchorLink.scrollIntoView({
            behavior: 'smooth',
            block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area. Offset is set via css.
          })
        }, 1500);
      }
    }
  }, []);

  return (
    <div
      className={"sr-only scroll-anchor"}
      id={link}
    />
  );
}

AnchorLink.propTypes = {
  link: PropTypes.string.isRequired
};

export default AnchorLink;
