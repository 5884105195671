import React from "react";
import PropTypes from "prop-types";
import Image from "../../../../../general-components/image/image";
import classNames from "classnames";
import ArrowRight from "../../../../../../assets/rbx-company/arrow-right.svg";

const ClickSuggestionTopic = ({ content }) => {

  const articleClassNames = classNames({
    "click-suggestion": true,
    "with-image": content.fieldImage,
    "no-image": !content.fieldImage
  });

  return(
      <article className={articleClassNames}>
        <div className="inner-wrapper">
          <div className="text-wrapper">
              <span className="headline body-m">
                {content?.fieldLink && (
                  <>{content.fieldLink.title}</>
                )}
                {content.fieldImage &&
                  <img
                    src={ArrowRight}
                    alt={"arrow right"}
                    className="arrow"
                  />
                }
              </span>
          </div>
          <div className="image-wrapper">
            {content.fieldImage ? (
              <div className="background-image">
                <Image
                  data={content.fieldImage.fieldMediaImage}
                />
              </div>
            ) : (
              <img
                src={ArrowRight}
                alt={"arrow right"}
                className="arrow"
              />
            )}
          </div>
        </div>
      </article>
    );
}

ClickSuggestionTopic.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.object,
    fieldLink: PropTypes.shape({
      url: PropTypes.shape({
        routed: PropTypes.bool,
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ClickSuggestionTopic;