import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useCanonicalOrRedirect } from "./use-canonical-or-redirect";
import { useI18n } from "../i18n/use-i18n";
import { useSelector } from "react-redux";

/**
 * Custom Hook to get node data from Drupal based on the current path.
 * This hook is used inside the node components.
 *
 * @param {object} query The GraphQL query to be executed. It must contain the $path variable.
 * @param {string} [forcedUrl] Optional parameter to overwrite the URL.
 * @returns {object} Object with entity, loading and error.
 */
const useNodeQuery = (query, forcedUrl) => {
  // get requested location from react router
  const location = useLocation();

  // get information about microsite and frontpage from redux store
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);
  const microSiteFrontPage = useSelector(
    (reduxStore) => reduxStore.appStore.microSiteFrontPage
  );

  let reducedPath =
    location.pathname === "/" && microSite && microSiteFrontPage !== ""
      ? microSiteFrontPage
      : location.pathname;

  if (reducedPath?.includes("#brand")) {
    reducedPath = `/${reducedPath.split("/")[1]}`;
  }

  /**
   * Hook to perform GraphQL query (with help of redux store)
   * Url can be overvwritten by forced URL needed to provide Overlay Functionality
   */
  const { data, loading, error } = useQuery(query, {
    variables: {
      path: forcedUrl || reducedPath,
    },
  });
  
  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  return { entity, loading, error };
};

export default useNodeQuery;
