import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import {FormattedMessage} from "react-intl";
import Appearances from "@nodes/shared-components/appearances/appearances";

const TeaserActShowcase = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const dispatch = useDispatch(),
    location = useLocation();


  {/*@todo: change this to appearance based, not participant based, remove display: none in css for double dates */}

  return (
    <article
      className="node node-teaser teaser-act showcase masked col-16 col-md-8"
      data-index={index}
    >
      <div className="row">
        <div className="mask-wrapper col-6 col-md-8">
          <EditButton
            adminApp={adminApp}
            entityId={item.id}
            destinationRoute={location.pathname}
          />
          {item.fieldImage && (
            <Image
              data={item.fieldImage.fieldMediaImage}
              nodeTitle={item.title}
            />
          )}
        </div>
        <div className="infos col-10 col-md-8">
          <Appearances
            detailedMode={false}
            content={item}
            type={"act"}
            showEndDate={true}
            forceLongDayName={false}
            showAddToCalendar={false}
          />
          <h3 className="headline-s">
            {item.label}
          </h3>
          <Link
            onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
            className="action-element body-xs"
            to={item.url?.path || item.path.alias}
          >
            <FormattedMessage id="learn_more" />
          </Link>
        </div>
      </div>
    </article>
  );
};

export const TeaserActShowcasePropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserActShowcase.propTypes = {
  item: TeaserActShowcasePropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserActShowcase;
