import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link as ReactRouterLink } from "react-router-dom";
import Image from "../../../image/image";
import classNames from "classnames";

const ClickSuggestion = ({ content }) => {

  const articleClassNames = classNames({
    "click-suggestion": true,
    "with-image": content.fieldImage,
    "no-image": !content.fieldImage,
    highlighted: content.fieldHighlighted,
  });

  return(
      <article className={articleClassNames}>
        <div className="inner-wrapper">
          <div className={"dashed-corner top left"} />
          <div className={"dashed-corner top right"} />
          <div className={"dashed-corner bottom left"} />
          <div className={"dashed-corner bottom right"} />
          <div className="content-wrapper">
            {content.fieldImage && (
              <div className="background-image">
                <Image
                  data={
                    content.fieldImage.fieldMediaImage
                  }
                />
              </div>
            )}
            <div className="text-wrapper">
              <span className="headline">
                {content?.fieldHeading && (
                  <>{content.fieldHeading}</>
                )}
              </span>
              <span className="subline">
                {content.fieldSubline}
              </span>
            </div>
          </div>
        </div>
      </article>
    );
}

ClickSuggestion.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldSubline: PropTypes.string,
    fieldImage: PropTypes.object,
    fieldLink: PropTypes.shape({
      url: PropTypes.shape({
        routed: PropTypes.bool,
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ClickSuggestion;