import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import useOverviewContext from "@paragraphs/overview/store/use-overview-context";
import moment from "moment";
import CustomAnimation from "@general-components/custom-animation";
import ChevronDown from "@/assets/ui/chevron-down-black.svg";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";
import ScrollSpy from "react-scrollspy-navigation";

const chars = [
  "@",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const times = [
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const Scrollspy = ({ groupedNodes, type = "chars", field = "title.0" }) => {
  const { fetchMore, nodes, metaData, loading } = useOverviewContext();
  const { setIsLoading } = usePageTransitionContext();

  const [isOpen, setIsOpen] = useState(false);

  const scrollSpy = useRef();
  const valueSelector = useRef();

  const toggleModal = (status) => {
    setIsOpen(status);
  };

  const getKeyHash = (key) => {
    if (type === "times") {
      return moment(key, "DD.MM.YYYY HH:mm").format("MM-DD-HH:mm");
    } else {
      return key[0];
    }
  };

  const getKeyLabel = (key) => {
    if (type === "times") {
      return moment(key, "DD.MM.YYYY HH:mm").format("HH:mm");
    } else {
      return key[0];
    }
  };

  /**
   * Recursive function to load nodes until a matching element is found.
   * _nodes need to be passed through, because the recursive function has no access
   * to the updated context nodes array.
   */
  const recursiveLoading = async (e, _nodes) => {
    setIsLoading(true);

    // Search for matching elements.
    let matchingNode;

    for (const index in _nodes) {
      const currentNode = _nodes[index];
      let nodeField = field.split(".").reduce((o, i) => o ? o[i] : null, currentNode);

      if (nodeField === null) {
        continue;
      }

      if (type === "chars") {
        nodeField = nodeField[0].toUpperCase();
      }
      if (type === "times") {
        nodeField = moment.unix(nodeField).format("HH:mm");
      }

      if (new Intl.Collator("de").compare(nodeField, e) >= 0) {
        matchingNode = currentNode;
        setIsLoading(false);
        break;
      }
    }

    // If there is no matching element and all nodes are loaded, scroll to the last element.
    if (_nodes.length >= metaData.totalRows && !matchingNode) {
      matchingNode = _nodes[_nodes.length - 1];
      setIsLoading(false);
    }

    // If there is a matching element, scroll to it.
    if (matchingNode) {
      setIsLoading(false);

      // Wait for the nodes to be rendered, then scroll to the matching node.
      setTimeout(() => {
        const matchingElement = document.querySelector(
          `[data-entity-id="${matchingNode.id}"]`
        );
        const position =
          matchingElement.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 100);
    }

    // If there are still nodes to load and no matching element was found, load more nodes.
    if (_nodes.length < metaData.totalRows && !loading && !matchingNode) {
      const newPage = Math.ceil(_nodes.length / metaData.perPage);
      let newNodes = [];
      // If there is no matching element, load more elements.
      await fetchMore({
        variables: { page: newPage },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const updatedData = Object.assign({}, prev, {
            entityById: {
              ...prev.entityById,
              executable: {
                ...prev.entityById?.executable,
                execute: {
                  ...prev.entityById?.executable?.execute,
                  rows: [
                    ...(prev.entityById?.executable?.execute?.rows || []),
                    ...(fetchMoreResult.entityById?.executable?.execute?.rows ||
                      []),
                  ],
                },
              },
            },
          });
          newNodes = updatedData.entityById?.executable?.execute.rows;

          return updatedData;
        },
      });

      // Call the recursive function again with the new nodes.
      recursiveLoading(e, newNodes);
    }
  };

  const clickHandler = (e) => {
    toggleModal(false);
    recursiveLoading(e, nodes);
  };

  return (
    <CustomAnimation type={"appear"} isVisible={true}>
      <div
        className="scrollspy filter-control-element"
        onClick={() => toggleModal(!isOpen)}
        data-is-open={isOpen}
        ref={scrollSpy}
      >
        <ScrollSpy
          activeClass="active"
          offsetTop={200}
          // onChangeActiveId={handleChange}
        >
          {Object.entries(groupedNodes).map(([key]) => (
            <a
              href={`#${getKeyHash(key)}`}
              className="body-xs"
              tabIndex={0}
              role="button"
              aria-label={`select Letter: ${getKeyLabel(key)}`}
              key={key}
            >
              {getKeyLabel(key)}
            </a>
          ))}
          <img
            className="chevron"
            src={ChevronDown}
            alt={"chevron down icon"}
          />
        </ScrollSpy>
      </div>
      <div data-is-open={isOpen} className="custom-modal scrollspy-select">
        <div className="values" ref={valueSelector}>
          {type === "chars" &&
            chars.map((char) => (
              <a
                className="body-xs"
                onClick={() => clickHandler(char)}
                key={char}
              >
                {char}
              </a>
            ))}

          {type === "times" &&
            times.map((time) => (
              <a
                className="body-xs"
                onClick={() => clickHandler(time)}
                key={time}
              >
                {time}
              </a>
            ))}

          {type === "native" &&
            Object.entries(groupedNodes).sort(([a], [b]) => b - a).map(([key]) => {
              return (
                <a
                  href={`#${getKeyHash(key)}`}
                  className="body-xs"
                  onClick={() => toggleModal(close)}
                  key={key}
                >
                  {getKeyLabel(key)}
                </a>
              );
            })}
        </div>
      </div>
    </CustomAnimation>
  );
};

Scrollspy.propTypes = {
  /**
   * The type defines the content in the scrollspy list.
   * "chars" displays the alphabet.
   * "times" displays the times.
   * "native" displays the group keys.
   */
  type: PropTypes.oneOf(["chars", "times", "native"]).isRequired,
  /**
   * The groupedNodes object contains the nodes grouped by a specific field.
   */
  groupedNodes: PropTypes.object,
  /**
   * Path to the field that should be used as a reference
   */
  field: PropTypes.string,
};

export default Scrollspy;
