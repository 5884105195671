import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DownloadIcon from "../../../../../assets/rbx-company/download-icon-black.svg";

const ParagraphSimpleDownload = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-simple-download": true,
    "with-borders": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-14 offset-md-1">
            {content.fieldFiles && content.fieldFiles.map((download, downloadIndex) =>
              <div className="download-wrapper" key={downloadIndex}>
                <div className="row">
                  <div
                    className={"infos col-14 offset-md-1 col-md-14"}
                  >
                    <h3 className="body-s">{download?.label}</h3>
                    {download?.fieldDescription && (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: download?.fieldDescription,
                        }}
                      />
                    )}
                  </div>
                  <div className="toggle-col col-2 col-md-1">
                    <a
                      className="toggle"
                      href={download?.mediaFileUrl.path}
                      download={true}
                    >
                      <img
                        src={DownloadIcon}
                        alt={"download"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphSimpleDownload.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldFile: PropTypes.shape({
      fieldPreviewImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      label: PropTypes.string.isRequired,
      fieldDescription: PropTypes.string,
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphSimpleDownload;
