import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";

import nodeRevisionQuery from "./node-revision-query.graphql";
import ContentBase from "../general-components/content-base/content-base";
import LoadingIndicator from "../general-components/loading-indicator";

const NodeRevision = (props) => {
  if (props.data.nodeRevisionById) {
    return (
      <article
        className={`node-${props.data.nodeRevisionById.entityBundle} node-${props.data.nodeRevisionById.entityId} node-full-page`}
      >
        <Helmet>
          <title>{`Base Site | ${props.data.nodeRevisionById.entityLabel}`}</title>
        </Helmet>

        <ContentBase
          content={props.data.nodeRevisionById.fieldModules}
          nodeContent={props.data.nodeRevisionById}
        />
      </article>
    );
  }

  return <LoadingIndicator />;
};

NodeRevision.propTypes = {
  data: PropTypes.object.isRequired,
};

export default graphql(nodeRevisionQuery, {
  options: (props) => ({
    variables: { revisionId: props.match.params.revisionId },
  }),
})(NodeRevision);
