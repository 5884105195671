import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {persistViewSwitchAction, releaseStateAction} from "@js/app-actions";
import Link from "../../../../../link/link";
import { useLocation, useNavigate } from "react-router-dom";

const ViewSwitch = memo(function ViewSwitch() {
  const location = useLocation();
  const navigate = useNavigate();

  const mainSettings = useSelector((state) => state.appStore.mainSettings);
  const dispatch = useDispatch();

  const [selectedView, setSelectedView] = useState(0);

  let selectableViews = [
    {
      "label": "Conference",
      "link": mainSettings?.fieldConferenceOverviewPage
    },
    {
      "label": "Festival",
      "link": mainSettings?.fieldFestivalOverviewPage
    }
  ]

  const toggleViewSwitch = (index) => {
    // Set activated view for highlighting
    setSelectedView(index);
    // Navigate to the overview Page, save the last page to the state to check it inside of app.jsx (to hide the viewSwitch when not needed)
    navigate(selectableViews[index].link?.uri?.path, {state: location.pathname});
    // Save Info if ViewSwitch should stay (if user changes from festival overview to conference overview)
    dispatch(persistViewSwitchAction(index === 1));
  }

  return (
    <div className="view-switch-wrapper">
      <div className="main-control view-switch header-element">
        <div className="border-element"/>
        <small className="sr-only label">Festival Branch</small>
        {selectableViews.map((item, index) =>
            <button
              key={index}
              className={index === selectedView ? "active font-style-change" : "font-style-change"}
              onClick={() => toggleViewSwitch(index)}
            >
        <span className="highlighted">
          {item.label}
        </span>
              <span className="standard">
          {item.label}
        </span>
            </button>
        )}
        <div className="effect-element"/>
      </div>
    </div>
  );
});

ViewSwitch.propTypes = {
  views: PropTypes.array,
  defaultView: PropTypes.string,
};

export default ViewSwitch;