import React from "react";
import PropTypes from "prop-types";
import DownloadGallery from "./download-gallery";
//import {getZipInfos} from "../../../../lib/get-zip-infos";

const DownloadIcon = () => {
  return (

    <div className="icon-wrapper download">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="34"
        fill="none"
        viewBox="0 0 30 34"
      >
        <path
          className="arrow"
          fill="#000"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0v22.854h-.75V0H15zm11.995 15.58L16.063 27.513l-.661-.354 10.931-11.935.662.354zM2 15.576l10.932 11.935.66-.354-10.93-11.935-.662.354z"
        />
        <path
          className="line"
          stroke="#000"
          strokeWidth="0.75"
          d="M0 33.627L30 33.627"/>
      </svg>
    </div>
  );
}

const DownloadItem = ({ content }) => {

  let file = content.fieldFile,
  fileInfos = {
    mimeType: content.fieldFile?.fieldMediaFile?.entity?.filemime.replace("application/", ""),
    size: Math.round((content.fieldFile?.fieldMediaFile?.entity?.filesize * 0.000001) * 100) / 100,
    elementsCount: false
  };

  return (
    <section className={"download-item col-16 col-md-8 col-lg-custom"}>
      <DownloadGallery content={content.fieldDownloadThumbnailsRawField.list}/>
      <a
        className="download-button"
        href={file.mediaFileUrl.path}
        download
      >
        <div className="info-wrapper">
          {file?.label &&
            <h3 className="title">{file.label}</h3>
          }
          {(fileInfos.mimeType || fileInfos.size) &&
            <div className={"meta-info-wrapper"}>
              {fileInfos.mimeType &&
                <span className="info mime-type">{fileInfos.mimeType}</span>
              }
              {fileInfos.size &&
                <span className="info size">{fileInfos.size} Mb</span>
              }
            </div>
          }
        </div>
        <DownloadIcon/>
      </a>
    </section>
  );
};

export default DownloadItem;
