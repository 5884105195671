import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FormattedMessage } from "react-intl";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: "",
    errorInfo: "",
  };

  static getDerivedStateFromError(/*error*/) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
    // You can also log the error to an error reporting service
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container error-message">
          <div className="row">
            <div className="col-16">
              <h1>
                <FormattedMessage id="error.general" />
              </h1>
              <pre>{this.state.error.message}</pre>

              {this.state.errorInfo.componentStack && (
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <button className="btn btn-primary more body-xs">
                          Error stack
                        </button>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <pre>{this.state.errorInfo.componentStack}</pre>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
