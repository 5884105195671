import React from "react";
import { FormattedMessage } from "react-intl";
import Image from "../../general-components/image/image";
import LazyLoad from "react-lazyload";

const Sponsors = ({content }) => {
  let sponsors = [...content.fieldCoopCompanyRawField?.list];

  if (content.fieldMainPresenterRawField) {
    sponsors = [...sponsors, ...content.fieldMainPresenterRawField.list];
  }

  if (sponsors && sponsors.length > 0) {
    return (
      <div className="sponsor-wrapper">
        <div className="all-sponsors">
          <div className="row">
            <div className="label body-xs col-16">
              <FormattedMessage id="cooperation" />
            </div>
            {sponsors && sponsors.length > 0 && sponsors.map((sponsor, index) =>
              <div className="sponsor main col" key={index}>
                <div className={`sponsor-image`}>
                  <a href={sponsor.entity?.fieldWebsite?.uri?.path} target="_blank">
                    {sponsor.entity.fieldImage &&
                      <>
                        <span className="sr-only">{sponsor.entity.title}</span>
                        {sponsor.entity.fieldImage &&
                          <LazyLoad>
                            <Image
                              data={sponsor.entity.fieldImage.fieldMediaImage}
                              nodeTitle={sponsor.entity.title}
                            />
                          </LazyLoad>
                        }
                      </>
                    }
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return false;
  }
};

export default Sponsors;
