import React from "react";
import PropTypes from "prop-types";

import Stop from "../../../../../assets/ui/tas-stop.svg";
import Listen from "../../../../../assets/ui/tas-zuhoeren.svg";

import useMousePosition from "./use-mouse-position";

const CustomCursor = (props) => {
  // 1.
  const { x, y } = useMousePosition();
  return (
    <div className="custom-cursor">
      {props.playing ? (
        <img
          className="pause"
          src={Stop}
          alt="stop"
          style={{ left: `${x}px`, top: `${y}px` }}
        />
      ) : (
        <img
          className="play"
          src={Listen}
          alt="listen"
          style={{ left: `${x}px`, top: `${y}px` }}
        />
      )}
    </div>
  );
};

CustomCursor.propTypes = {
  playing: PropTypes.bool,
};

export default CustomCursor;
