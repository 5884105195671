import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import Tags from "../../tags/tags";
import { useLocation } from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserNewsTimeline = ({ item, pagerFullPage = false, ...props }) => {
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-news teaser-news-timeline">
      <EditButton
        adminApp={props.adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() =>
          props.dispatch(pagerFullPageContextAction(pagerFullPage))
        }
        className="flex-wrap"
        to={item.url?.path || item.path?.alias}
      >
        {item.fieldImage && (
          <Image
            data={item.fieldImage.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}

        <FieldTime
          timestamp={true}
          date={
            item.publishedAtRawField?.first.value ||
            item.publishedAtRawField?.first.publishedAtOrNow
          }
          format={"DD.MM.YYYY"}
        />

        <h3>{item.title}</h3>

        {item.fieldText && (
          <div
            className="teaser text"
            dangerouslySetInnerHTML={{
              __html: item.fieldText,
            }}
          />
        )}

        <Tags tags={item.fieldTags} />
      </Link>
    </article>
  );
};

TeaserNewsTimeline.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(TeaserNewsTimeline);
