import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "@general-components/tags/tags";
import { useLocation } from "react-router-dom";

const TeaserGeneral = ({ pagerFullPage = false, item, ...props }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-news">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        {item.fieldImage && (
          <Image
            data={item.fieldImage.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}
        <div className="text-wrapper">
          <Tags tags={item.fieldTags} />
          <h3>{item.title}</h3>
        </div>
      </Link>
    </article>
  );
};

export const teaserGeneralPropTypes = PropTypes.shape({
  id: PropTypes.string,
  activeBefore: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldTags: PropTypes.array,
});

TeaserGeneral.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserGeneralPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserGeneral;
