import React from "react";
import useOverviewContext from "@paragraphs/overview/store/use-overview-context";
import { FormattedMessage } from "react-intl";

/**
 * Component for the sort controls.
 * This component will display buttons for sorting the nodes by date or venue.
 * @returns {JSX.Element} The SortControls component.
 */
const SortControls = () => {
  const { searchParams, setSearchParams } = useOverviewContext();

  const sort = searchParams.get("sort") || "field_date-ASC";

  const changeSortHandler = (newSort) => {
    setSearchParams((_searchParams) => {
      _searchParams.set("sort", newSort);
      return _searchParams;
    });
  };

  return (
    <div
      className="main-control group-switch filter-control-element sort-controls"
      data-sort-state={sort}
      style={{
        "--animation-children": 2,
        "--current-index": sort === "field_date-ASC" ? 0 : 1,
      }}
    >
      <small className="d-block label sr-only">
        <FormattedMessage id="overview.sort_by" />
      </small>
      <button
        className={
          sort === "field_date-ASC"
            ? "active font-style-change"
            : "font-style-change"
        }
        onClick={() => changeSortHandler("field_date-ASC")}
      >
        <span className="highlighted">
          <FormattedMessage id="overview.time" />
        </span>
        <span className="standard">
          <FormattedMessage id="overview.time" />
        </span>
      </button>
      <button
        className={
          sort === "field_venue-ASC"
            ? "active font-style-change"
            : "font-style-change"
        }
        onClick={() => changeSortHandler("field_venue-ASC")}
      >
        <span className="highlighted">
          <FormattedMessage id="overview.venue" />
        </span>
        <span className="standard">
          <FormattedMessage id="overview.venue" />
        </span>
      </button>
    </div>
  );
};

SortControls.propTypes = {};

export default SortControls;
