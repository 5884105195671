import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";
import MediaSwitch from "../../../media-switch";

const ParagraphTeaserImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-image": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div
          className={classNames({
            row: true,
            "image-left":
              content.fieldImagePosition === "left",
            "image-right flex-row-reverse":
              content.fieldImagePosition === "right",
          })}
        >
          <div
            className={classNames({
              "col-16 col-lg-8 image-col": true
            })}
          >
            {content.fieldMultimediaSingle &&
              <MediaSwitch
                media={content.fieldMultimediaSingle}
                item={content.fieldMultimediaSingle}
                autoplay={true}
              />
            }
          </div>
          <div
            className={classNames({
              "col-16 col-lg-8 text-col": true
            })}
          >
            <h2>{content.fieldHeading}</h2>
            {content.fieldText && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: content.fieldText,
                }}
              />
            )}
            {content.fieldCallToAction && (
              <Link
                className="learn-more"
                link={content.fieldCallToAction}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserImage.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldMultimediaSingle: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.string,
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
