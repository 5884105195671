import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { useSelector } from "react-redux";
import { waitForElm } from "../../../../../lib/wait-for-elm";
import TeaserActMasked from "../../../../teaser-base/act/teaser-act-masked";

const ActsCarousel = ({ nodes }) => {
  const [goForwards, setGoForwards] = useState(true);
  const [orientation, setOrientation] = useState("next");
  const [maxWidth, setMaxWidth] = useState(0);

  const carousel = useRef();

  const isMobile = useSelector((state) => state.appStore.isMobile);

  useEffect(() => {
    // Pager on full screen pages.
    //this.pushPagerFullPageConfig();
    if (maxWidth === 0) {
      if (carousel.current) {
        waitForElm(
          ".participant-wrapper .node-teaser:last-child",
          carousel.current
        ).then((elm) => {
          calculateMaxWidth();
        });
      } else {
        // Fixing autoplay when there are initialization hickups
        setTimeout(() => {
          waitForElm(
            ".participant-wrapper .node-teaser:last-child",
            carousel.current
          ).then((elm) => {
            calculateMaxWidth();
          });
        }, 1500);
      }
    }
  }, []);

  const calculateMaxWidth = () => {
    let maxWidth = carousel.current
      .querySelector(".participant-wrapper .node-teaser:last-child")
      .getBoundingClientRect().right;

    carousel.current.style.setProperty(
      "--marquee-endpoint",
      `${-maxWidth + window.innerWidth}px`
    );
    setMaxWidth(-maxWidth + window.innerWidth);
  };

  const spinCarousel = (currentOrientation) => {
    // Make sure the spin can start. It can only work if maxWidth is initialized.
    if (maxWidth === 0) {
      calculateMaxWidth();
    }
    // get transition quotient by getting the scrolled position in this second.
    //@todo: This calculation colud be improved
    let transitionQuotient = Math.abs(
      goForwards
        ? carousel.current
            .querySelector(".participant-wrapper .node-teaser:first-child")
            .getBoundingClientRect().left / maxWidth
        : 1 -
            carousel.current
              .querySelector(".participant-wrapper .node-teaser:first-child")
              .getBoundingClientRect().left /
              maxWidth
    );

    // cannot be 0! otherwise it just jumps
    if (transitionQuotient === 0) {
      transitionQuotient = 1;
    }
    // Fallback for minimum transition
    if (transitionQuotient < 0.2) {
      transitionQuotient = 0.2;
    }

    if (currentOrientation !== orientation) {
      carousel.current.style.setProperty(
        "--transition-quotient",
        transitionQuotient
      );
    }

    if (currentOrientation === "next") {
      setOrientation(orientation);
      setGoForwards(true);
    } else {
      setOrientation(orientation);
      setGoForwards(false);
    }
  };

  return (
    <div className="carousel-wrapper" ref={carousel}>
      <VisibilitySensor
        partialVisibility={true}
        // This offset ensures that only the centered value is visible.
        offset={{
          top: isMobile ? 0 : 300,
        }}
        once={true}
      >
        {({ isVisible }) => (
          <div
            className={`scrollspy-wrapper ${
              isVisible ? "active" : "inactive"
            } ${goForwards ? "to-right" : "to-left"}`}
          >
            <>
              {!isMobile && (
                <>
                  {/* @todo: Accesibility */}
                  <button
                    className="carousel-control prev"
                    aria-label={"show prev content"}
                    role={"button"}
                    tabIndex={0}
                    onMouseEnter={() => spinCarousel("prev")}
                    //disabled={slideIndex === 0}
                  />
                  <button
                    className="carousel-control next"
                    tabIndex={0}
                    aria-label={"show next content"}
                    onMouseEnter={() => spinCarousel("next")}
                    //disabled={nodes.length / 2 === slideIndex}
                  />
                </>
              )}
              <div className="overflow-wrapper">
                <div className={"participant-wrapper"}>
                  {nodes.map((item, index) => {
                    if (!item) {
                      return null;
                    }

                    return (
                      <React.Fragment key={index}>
                        <TeaserActMasked
                          item={item}
                          index={index}
                          forceMask={index - 1}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
};

ActsCarousel.propTypes = {
  nodes: PropTypes.object.isRequired,
};

export default ActsCarousel;
