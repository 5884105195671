import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TeaserBrand from "@teasers/brand/teaser-brand";

const ParagraphBrandsOverview = ({ content }) => {
  const mainClassNames = classNames({
    'paragraph brands-overview': true,
    'simple': content.fieldSimpleVersion,
    'complex': !content.fieldSimpleVersion
  });

  return (
    <div className={mainClassNames}>
      <div className={"container-fluid"}>
        <div className="row">
          {content.fieldBrands?.map((item, index) =>
            <React.Fragment key={index}>
              <TeaserBrand
                item={item}
                index={index + 1}
                simpleVersion={content.fieldSimpleVersion}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ParagraphBrandsOverview.propTypes = {
  content: PropTypes.object
};

export default ParagraphBrandsOverview;