import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

import { LinkPropType } from "../../../link/link";
import Image from "../../../image/image";

const ParagraphAdvertisingBanner = ({content}) => {
  const sliderSettings = {
    adaptiveHeight: false,
    arrows: false,
    centerMode: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    touchMove: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let ads = [...content.fieldAds];

    // shuffle ads
    for (let i = ads.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = ads[i];
      ads[i] = ads[j];
      ads[j] = temp;
    }


  const sectionClassNames = classNames({
    "paragraph paragraph-advertising-banner": true,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className="row">
          <div className="col-16">
            <h3>{content.fieldHeading}</h3>
          </div>
          <div className="col-16 col-lg-12 offset-lg-2">
            {ads.length > 1 ? (
              <Slider {...sliderSettings}>
                {ads.map((item, index) => (
                  <article className="ad" key={index}>
                    {item.fieldLink ? (
                      <a
                        rel="noopener noreferrer"
                        href={item.fieldLink.uri.path}
                        target="_blank"
                      >
                        {item.fieldMediaImage && (
                          <LazyLoad offset={500}>
                            <Image data={item.fieldMediaImage} />
                          </LazyLoad>
                        )}
                        {/*item.fieldLabel && (
                          <h2>{item.fieldLabel}</h2>
                        )*/}
                      </a>
                    ) : (
                      <>
                        {item.fieldMediaImage && (
                          <LazyLoad offset={500}>
                            <Image data={item.fieldMediaImage} />
                          </LazyLoad>
                        )}
                        {/*item.fieldLabel && (
                          <h2>{item.fieldLabel}</h2>
                        )*/}
                      </>
                    )}
                  </article>
                ))}
              </Slider>
            ) : (
              <article className="ad single">
                {ads[0] &&
                  <>
                    {ads[0].fieldLink ? (
                      <a
                        rel="noopener noreferrer"
                        href={ads[0].fieldLink?.uri?.path}
                        target="_blank"
                      >
                        {ads[0].fieldMediaImage && (
                          <LazyLoad offset={500}>
                            <Image data={ads[0].fieldMediaImage} />
                          </LazyLoad>
                        )}
                      </a>
                    ) : (
                      <>
                        {ads[0].fieldMediaImage && (
                          <LazyLoad offset={500}>
                            <Image data={ads[0].fieldMediaImage} />
                          </LazyLoad>
                        )}
                      </>
                    )}
                  </>
                }
              </article>
            )}

          </div>
        </div>
      </div>
    </section>
  );
}

ParagraphAdvertisingBanner.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLinks: PropTypes.arrayOf(LinkPropType),
    fieldAds: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldLabel: PropTypes.string,
          fieldLink: PropTypes.shape({
            uri: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
            }),
          }),
          fieldMediaImage: PropTypes.object,
        }),
      })
    ),
  }),
};

export default ParagraphAdvertisingBanner;
