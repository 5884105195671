import React from "react";
import audioTranscriptionIcon from "../../../../../assets/ui/audio-transcription-icon.svg";
import {useSelector} from "react-redux";
import AccessibilityIcons from "@general-components/accessibility-icons";

const TimetableAccessibilityInfos = ({ content, type }) => {
  const happeningContent = content.fieldHappening;

  if (happeningContent?.fieldSpokenLanguage?.length > 0 || content.fieldVenueLocation?.fieldAccesibility) {
    return (
      <div className="accessibility inline-infos d-flex">
        {happeningContent?.fieldSpokenLanguage?.length > 0 && happeningContent?.fieldSpokenLanguage.map(
          (spokenLanguage, spokenLanguageId) => {
            if (spokenLanguage.fieldLanguageId === "Audio-Transkript") {
              return (
                <div key={spokenLanguageId} className="icon audio-transcript">
                  <img
                    src={audioTranscriptionIcon}
                    alt={"Audio Transcript available"}
                  />
                </div>
              );
            } else {
              return (
                <div
                  className="tag black"
                  key={`${spokenLanguageId}-${spokenLanguage}`}
                >
                  {spokenLanguage?.fieldLanguageId ? (
                    <>{spokenLanguage?.fieldLanguageId}</>
                  ) : (
                    <>{spokenLanguage?.name}</>
                  )}
                </div>
              );
            }
          }
        )}
        {content.fieldVenueLocation?.fieldAccesibility &&
          <AccessibilityIcons
            content={content.fieldVenueLocation.fieldAccesibility}
          />
        }
      </div>
    );
  }
};

export default TimetableAccessibilityInfos;
