import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Link from "../../../general-components/link/link";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "@js/app-actions";
import ColorMask from "@js/participants/components/color-mask";

const TeaserActMasked = ({ item, index, forceMask }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const dispatch = useDispatch(),
    location = useLocation();

  return (
    <article
      className="node node-teaser teaser-act masked infos-on-hover"
      data-index={index}
    >
      <div className="mask-wrapper">
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location.pathname}
        />
        <Link
          className="flex-wrap"
          link={item}
          forceSameTab={true}
        >
          {item.fieldImage && (
            <>
              <div className="image">
                <img
                  src={item.fieldImage.fieldMediaImage.style.url}
                  alt={item.fieldImage.fieldMediaImage.alt}
                />
                <ColorMask
                  type={"teaser-participant"}
                  index={index}
                  useCssMask={true}
                />
              </div>
            </>
          )}
          <div className="hover-infos">
            <h3 className="name">{item.title}</h3>
          </div>
        </Link>
      </div>
    </article>
  );
};

export const TeaserActMaskedPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserActMasked.propTypes = {
  item: TeaserActMaskedPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default TeaserActMasked;
