import React from "react";
import TeaserSpeaker from "../../../../teaser-base/speaker/teaser-speaker";
import useOverviewContext from "../store/use-overview-context";
import _ from "lodash";
import Controls from "../components/main-controls";
import ScrollSpy from "../components/main-controls/scrollspy";

const OverviewSpeakers = () => {
  const { nodes } = useOverviewContext();

  let groupedNodes = {};
  if (nodes?.length && nodes[0].label)
    groupedNodes = _.groupBy(nodes, (node) => {
      return node.label[0].toUpperCase();
    });

  if (groupedNodes) {
    return (
      <div className="speakers-overview container no-padding">
        <Controls>
          <div className="col scrollspy">
            <ScrollSpy
              groupedNodes={groupedNodes}
              field="title.0"
              type="chars"
            />
          </div>
        </Controls>

        <div className="row">
          {Object.entries(groupedNodes).length &&
            Object.entries(groupedNodes).map(([key, group]) => (
              <div className="alphabet-wrapper row" id={key} key={key}>
                {group.map((node) => (
                  <TeaserSpeaker
                    key={node.id}
                    item={node}
                    pagerFullPage={false}
                    updateUrl={true}
                  />
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default OverviewSpeakers;
