import React from "react";

const BusinessWrapper = ({content}) => {
  if (content.fieldCompany) {
    return (
      <div className="business-wrapper text body-xs">
        <p>
          <span className="company body-xs">
            {content.fieldCompany}
          </span>{" "}
          {content.fieldPosition && (
            <span className="position body-xs">
              / {content.fieldPosition}
            </span>
          )}
        </p>
      </div>
    );
  } else {
    return false;
  }
};

export default BusinessWrapper;
