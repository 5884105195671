import React, { Component } from "react";
import PropTypes from "prop-types";

import TeaserAct from "./act/teaser-act";

const TeaserParticipantSwitch = (props) => {
  switch (props.item.fieldParticipantType) {
    case "act":
      return (
        <TeaserAct
          item={props.item}
          pagerFullPage={props.pagerFullPage}
        />
      );
    default:
      return null;
  }
}

TeaserParticipantSwitch.propTypes = {
  item: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
};

export default TeaserParticipantSwitch;
