import React, { lazy, Suspense, useEffect, useState } from "react";
import memoize from "memoize-one";

// Router
import { Route, Routes, Switch, useLocation, useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import PageFooter from "@js/footer/page-footer";
import Header from "@js/header/header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import WebformPage from "./intern/webform-page/webform-page";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import LoginForm from "./intern/login-form";

// Nodes
//const Landingpage = lazy(() => import("@nodes/landingpage/landingpage"));
//const Act = lazy(() => import("@nodes/act/act"));
//const Speaker = lazy(() => import("@nodes/speaker/speaker"));
import Event from "@nodes/event/event";
import Person from "@nodes/person/person";
import News from "@nodes/news/news";

import Error404 from "@system/error-404";
import {isMobileAction, persistViewSwitchAction} from "./app-actions";

// Page Transition Stuff
import { AnimatePresence } from "framer-motion";
import LoadingIndicator from "@general-components/loading-indicator";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";
import Speaker from "@nodes/speaker/speaker";
import Landingpage from "@nodes/landingpage/landingpage";
import Act from "@nodes/act/act";

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  und: defaultMessages,
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 */
const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);
  const isMobile = useSelector((state) => state.appStore.isMobile);
  const mainSettings = useSelector((state) => state.appStore.mainSettings);
  const persistViewSwitch = useSelector((state) => state.appStore.persistViewSwitch);
  const dispatch = useDispatch();

  const { setMinHeight } = usePageTransitionContext();

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  // Dispatch size change to store to determine if screen is mobile or not
  const handleWindowSizeChange = () => {
    const isMobile = window.innerWidth <= 768;

    setMinHeight();
    dispatch(isMobileAction(isMobile));
  };

  useEffect(() => {
    //  Register resize Handler for isMobile Check
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    /* Check if user came from conference overview. Otherwise, the viewSwitch should not be displayed.
    * @todo: i do not like the way this works, i would prefer to use a history, not a parameter passed via state which i have to pass inside of the view switch component.
    */
    if (location.state && location.state !== mainSettings?.fieldConferenceOverviewPage?.uri?.path || location.pathname !== mainSettings?.fieldFestivalOverviewPage?.uri?.path) {
      dispatch(persistViewSwitchAction(false));
    }

    // Check if page is an overview and add class to it if so
    let isOverview = false;

    if (location.pathname === mainSettings?.fieldConferenceOverviewPage?.uri?.path) {
      isOverview = "conference-overview"
    } else if (location.pathname === mainSettings?.fieldFestivalOverviewPage?.uri?.path) {
      isOverview = "festival-overview"
    }

    // Set Standard Classnames, changed "+=" to "=", removed the whole block from initial useEffect to a Hook that listens for location changes
    document.body.className = `${generateMainClasses(location.pathname)} ${isOverview}`;
  }, [location]);

  return (
    <IntlProvider
      locale={currentLanguage !== "und" ? currentLanguage : defaultLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLanguage]}
    >
      <div className="main-content">
        <ErrorBoundary>
          <Header showSearch={false} />
        </ErrorBoundary>

        <LoadingIndicator overlay={true} />

        <main className="main-page-content">
          <ErrorBoundary>
            <AnimatePresence
              mode="wait"
              initial={false}
              onExitComplete={() => {
                if (typeof window !== "undefined") {
                  window.scrollTo({ top: 0, behavior: "instant" });
                }
              }}
            >
              <Routes location={location} key={location.pathname}>
                {/* Internal Pages */}
                <Route exact path="/intern/login" element={<LoginForm />} />
                <Route
                  exact
                  path="/intern/forgot-password"
                  element={<ForgotPasswordForm />}
                />
                <Route
                  exact
                  path="/intern/reset-pass/temp"
                  element={<ResetPasswordWithTempPasswordForm />}
                />
                <Route
                  exact
                  path="/intern/webform/:webformId/:submissionId?"
                  element={<WebformPage />}
                />
                <Route exact path="/intern/:alias" element={<Landingpage />} />
                {/* Public Pages */}
                <Route
                  exact
                  path="/:language?/projekt/:alias"
                  element={<Landingpage />}
                />
                <Route
                  exact
                  path="/:language?/news/:alias"
                  element={<News />}
                />
                <Route
                  exact
                  path="/:language?/press/:alias"
                  element={<News />}
                />
                <Route
                  exact
                  path="/:language?/event/:alias"
                  element={<Event />}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  element={<Person />}
                />
                <Route exact path="/:language?/act/:alias" element={<Act />} />
                <Route
                  exact
                  path="/:language?/speaker/:alias"
                  element={<Speaker />}
                />
                <Route
                  exact
                  path="/:language?/:alias"
                  element={<Landingpage />}
                />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  element={<NodeRevision />}
                />
                <Route exact path="/:language?" element={<Landingpage />} />
                <Route exact path="/" element={<Landingpage />} />
                <Route path="*" element={<Error404 />} status={404} />
              </Routes>
            </AnimatePresence>
          </ErrorBoundary>
        </main>

        <ErrorBoundary>
          <PageFooter />
        </ErrorBoundary>
      </div>
    </IntlProvider>
  );
};

App.propTypes = {};

export default App;
