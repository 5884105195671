import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import Image from "../../image/image";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import ContactInformation from "./components/contact-information";
import Role from "./components/role";

const TeaserPersonContact = ({ item, showImage = true }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-person teaser-contact">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <div className="row">
        {showImage && item.fieldImage?.first ? (
          <div className="col-16 col-lg-8 image">
            <Image
              data={item.fieldImage.first.entity.fieldMediaImage}
              nodeTitle={item.title}
            />
          </div>
        ) : (
          <div className="col-16 col-lg-8 initial-col">
            <div className="initials body-m">
              {/* Get first letter of First Name */}
              {item.title[0]}

              {/* Get first letter of Last Name */}
              {item.title.split(" ")[item.title.split(" ").length - 1][0]}
            </div>
          </div>
        )}

        <div
          className={classNames({
            "col-16 col-lg-8 info": true
          })}
        >
          <div className="info-wrapper">
            <h3 className="headline body-m">{item.title}</h3>
            {item.fieldCompany && (
              <div
                className="info company body-xs"
                dangerouslySetInnerHTML={{ __html: item.fieldCompany }}
              />
            )}
            {item.fieldPosition && (
              <div
                className="info position body-xs"
                dangerouslySetInnerHTML={{ __html: item.fieldPosition }}
              />
            )}
            {item.fieldText && (
              <div
                className="text body-xs"
                dangerouslySetInnerHTML={{ __html: item.fieldText }}
              />
            )}
            <ContactInformation content={item} />
          </div>
        </div>
      </div>
    </article>
  );
};

TeaserPersonContact.propTypes = {
  item: teaserPersonPropTypes,
  showImage: PropTypes.bool.isRequired,
};

TeaserPersonContact.defaultProps = { showImage: true };

export default TeaserPersonContact;
