import React from "react";

import eventPageQuery from "./event.graphql";
import useNodeQuery from "@hooks/use-node-query";
import NodeWrapper from "@nodes/shared-components/node-wrapper";

import Appearances from "@nodes/shared-components/appearances/appearances";
import MetaInfos from "@js/participants/components/meta-infos";
import DeezerWidget from "@js/participants/components/deezer-widget";
import LookingFor from "@js/participants/components/looking-for";
import SocialLinks from "@js/participants/components/social-links";
import Presenter from "@js/participants/components/presenter";
import Related from "@js/participants/components/related/related";
import DetailPageIntro from "@nodes/shared-components/detail-page-intro";
import ParticipatingPersons from "@nodes/shared-components/participating-persons";
import ShowcaseActs from "@nodes/shared-components/participating-acts";
import LinkedEvents from "@teasers/event/components/linked-events";

const Event = () => {
  const { entity, loading, error } = useNodeQuery(eventPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      <DetailPageIntro
        content={entity}
        mode={"event"}
      />
      <div className="container max-width main-container">
        <div className="row infos">
          <div className={"col-16 appearances-wrapper"}>
            <Appearances
              detailedMode={true}
              content={entity}
              type={"event"}
              showAddToCalendar={true}
              viewMode={"detail"}
              showEndDate={true}
            />
          </div>

          <div className={"col-16 col-lg-4 meta-info-wrapper"}>
            <MetaInfos
              content={entity}
            />
          </div>
        </div>
        <div className="row with-gaps">
          {entity?.fieldText &&
            <div className="col-16 text-wrapper">
              <div
                className={"text headline-subhead"}
                dangerouslySetInnerHTML={{ __html: entity.fieldText }}
              />
            </div>
          }

          <LinkedEvents
            content={entity}
          />

          <ParticipatingPersons
            content={entity}
          />

          <ShowcaseActs
            content={entity}
          />

          <div className="col-16 col-lg-8 left-col">
            <DeezerWidget
              content={entity}
            />
            <LookingFor
              content={entity}
            />
            <SocialLinks
              content={entity}
            />
          </div>
          <div className="col-16 col-lg-8 right-col">
            <Presenter
              content={entity}
            />
          </div>
        </div>
      </div>
      <div className={"container-fluid related-container"}>
        <div className="col-16 related-events">
          <Related
            content={entity}
            type={"event"}
          />
        </div>
      </div>
    </NodeWrapper>
  );
};

Event.propTypes = {};

export default Event;
