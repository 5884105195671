import React from "react";
import DateWrapper from "./date-wrapper";

const FeaturedEvents = ({content}) => {

  if (content.fieldAppearances && content.fieldAppearances.length >= 1) {
    return (
      <div className={"featured-event-wrapper"}>
        {content.fieldAppearances.map(
          (featuredEvent, index) => (
            <div className="featured-on-event" key={index}>
              {featuredEvent.fieldHappening?.label && (
                <div className="title make-headline-light">
                  {featuredEvent.fieldHappening.label}
                </div>
              )}
              <DateWrapper
                content={featuredEvent}
              />
            </div>
          )
        )}
      </div>
    );
  } else {
    return (
      <div className={"featured-event-wrapper"}>
        <div className="title make-headline-light">
          TBA
        </div>
      </div>
    );
  }
};

export default FeaturedEvents;
