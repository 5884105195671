import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphPageTitle = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-page-title": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-10">
            <h1>{content.fieldHeading}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphPageTitle.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
  }),
};

export default ParagraphPageTitle;
