import React from "react";
import {FormattedMessage} from "react-intl";

const Presenter = ({content, dayFilterEnabled}) => {
  let presenters = [];

  if (content.fieldMainPresenterCompany) {
    presenters.push(content);
  }
  {/* Show Presentators of other plays as well @todo: needs rework because of new structure which will be given shortly */}
  if (content.fieldDuplicates && !dayFilterEnabled) {
    content.fieldDuplicates.map((duplicate, index) => {
      if (duplicate && duplicate.entity && duplicate.entity.fieldMainPresenterCompany) {
        // DO not show presenter twice if it is the same
        if (!content.fieldMainPresenterCompany === duplicate.entity.fieldMainPresenterCompany) {
          presenters.push(duplicate.entity);
        }
      }
    });
  }

  if (presenters && presenters.length > 0) {
    return (
      <div className="presented-by">
        <div className="label">
          <FormattedMessage id="presented" />
        </div>
        {presenters.map((item, index) =>
          <div className="presentator" key={index}>
            {item.fieldMainPresenterWeb ? (
              <>
                {item.fieldMainPresenterWeb.uri && (
                  <>
                    <a
                      href={item.fieldMainPresenterWeb.uri}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.fieldMainPresenterCompany}
                    </a>
                  </>
                )}
              </>
            ) : (
              <span>
                {item.fieldMainPresenterCompany}
              </span>
            )}
            <span className="comma">{presenters.length > 1 && index < presenters.length - 1 ? "," : ""}</span>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

export default Presenter;
