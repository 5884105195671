import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import getEncodedIcalUrl from "../../../../lib/get-encoded-ical-url";
import Tags from "../../../tags/tags";

moment.locale("de");

/**
 * Usually the first module on an event page - event intro module with most
 * important information.
 */
const ParagraphEventInformation = ({ nodeContent }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-event-information": true,
  });

  const startDateRaw = moment.utc(nodeContent.fieldDateRawField?.list.value).local();
  const endDateRaw = moment.utc(nodeContent.fieldDateRawField?.list.value).local();

  const isFullDay = startDateRaw.format("HH:mm") === "00:00";

  const duration =
    !nodeContent.fieldShowDuration &&
    nodeContent.fieldDateRawField?.list.endValue &&
    Math.round(moment.duration(endDateRaw.diff(startDateRaw)).asHours());

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-12">
            <Tags tags={nodeContent.fieldTags} />
            <h1>{nodeContent.title}</h1>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 text"
            dangerouslySetInnerHTML={{ __html: nodeContent.fieldText }}
          />
        </div>
        <div className="row">
          <div className="col-8 col-lg-3">
            <p className="date-wrapper">
              <span>{startDateRaw.format("dddd")}</span>
              <span>{startDateRaw.format("DD.MM.YYYY")}</span>
              {!isFullDay && <span>{startDateRaw.format("HH:mm")}</span>}
              {!!duration && <span className="duration">{duration} Std.</span>}
            </p>
          </div>
          {nodeContent.fieldLocation &&
            <div className="col-8 col-lg-3">
              <p>{nodeContent.fieldLocation}</p>
            </div>
          }

          <div className="col-16 col-lg-10 buttons-wrapper">
            <a
              href={getEncodedIcalUrl(
                nodeContent.fieldDate,
                nodeContent.fieldLocation || "",
                nodeContent.title,
                nodeContent.fieldText || ""
              )}
              className="btn btn-primary"
            >
              <FormattedMessage id="event.remember" />
            </a>
            {nodeContent.fieldTicket && (
              <a
                href={nodeContent.fieldTicket.uri.path}
                className="btn btn-primary"
              >
                {nodeContent.fieldTicket.title || (
                  <FormattedMessage id="event.ticket" />
                )}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphEventInformation.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.object,
  nodeContent: PropTypes.shape({
    title: PropTypes.string,
    fieldShowDuration: PropTypes.bool,
    fieldDate: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldLocation: PropTypes.string,
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    fieldText: PropTypes.string,
    fieldTicket: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphEventInformation;
