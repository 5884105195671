import React from "react";
import AppearanceDetailed from "@nodes/shared-components/appearances/appearance-detailed";
import AppearanceSimple from "@nodes/shared-components/appearances/appearance-simple";

const Appearances = ({ content, type, showAddToCalendar, viewMode, detailedMode, showEndDate, forceLongDayName }) => {
  return(
    <div className="appearances">
      {content.fieldAppearances.map((appearance, appearanceId) =>
        <React.Fragment key={appearanceId}>
          {detailedMode ? (
            <AppearanceDetailed
              appearance={appearance}
              type={type}
              showAddToCalendar={showAddToCalendar}
              showEndDate={showEndDate}
              viewMode={viewMode}
              appearanceId={appearanceId}
              content={content}
              forceLongDayName={forceLongDayName}
            />
          ) : (
            <AppearanceSimple
              appearance={appearance}
              type={type}
              showAddToCalendar={showAddToCalendar}
              showEndDate={showEndDate}
              viewMode={viewMode}
              appearanceId={appearanceId}
              content={content}
              forceLongDayName={forceLongDayName}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default Appearances;
