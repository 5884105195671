import { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentLanguage, setTranslations } from "./i18n-slice";

const DEFAULT_LANGUAGE = "de";
const VALID_LANGUAGES = ["de", "en", "und"];

const useI18n = (languageSwitchLinks = []) => {
  const params = useParams();
  const dispatch = useDispatch();

  const activeTranslation = languageSwitchLinks.find((link) => link.active);
  const languageFromParams =
    params.language && VALID_LANGUAGES.includes(params.language)
      ? params.language
      : DEFAULT_LANGUAGE;

  let currentLanguage = activeTranslation
    ? activeTranslation.language.id
    : languageFromParams;

  useEffect(() => {
    moment.locale(currentLanguage);

    dispatch(setCurrentLanguage(currentLanguage));
    dispatch(setTranslations(languageSwitchLinks));
  }, [currentLanguage, JSON.stringify(languageSwitchLinks), dispatch]);
};

export { useI18n };
