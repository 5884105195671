import React from "react";
import PropTypes from "prop-types";
import ScrollOverview from "../../scroll-overview/scroll-overview";

const ActsList = ({ nodes }) => {

  return(
    <div className="list-wrapper">
      <ScrollOverview
        type={"participant"}
        participantTypeFilter={"act"}
        nodes={nodes}
        tags={false}
        showBanners={false}
        updateUrl={false}
        displayType={"list"}
        clickBehavior={"link"}
      />
    </div>
  );
}

ActsList.propTypes = {
  nodes: PropTypes.object.isRequired
};

export default ActsList;
