import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import teaserNodeQueryRelatedActs from "./related-acts-query.graphql";
import teaserNodeQueryRelatedEvents from "./related-events-query.graphql";
import TeaserParticipantGeneralRelated from "@teasers/participant-general/teaser-participant-general-related";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Link from "./../../../general-components/link/link";
import LoadingIndicator from "@general-components/loading-indicator";

// Get two random Artists from Query
const getRandomElements = (relatedContent) => {

  if (relatedContent?.entityQuery?.total < 1) return false;
  const items = relatedContent.entityQuery.items,
    total = relatedContent.entityQuery.total;

  let random1 = Math.floor(Math.random() * total),
    random2 = Math.floor(Math.random() * total);

  // If the very rare thing happens, that both random values are equal, take a smaller one. If they are also the same... then some higher order will chose it and it will happen :D
  if (random1 === random2) {
    random2 = Math.floor(Math.random() * random2);
  }

  console.log(items, items[random1],
    items[random2])

  return [
    items[random1],
    items[random2]
  ];
};

const Related = ({ content, type }) => {
  const mainSettings = useSelector(
    (reduxStore) => reduxStore.appStore.mainSettings
  );
  const [relatedContent, setRelatedContent] = useState([]);

  const {
    data: relatedActs,
    loading: loadingActs,
    error: errorActs,
  } = useQuery(teaserNodeQueryRelatedActs, {
    variables: {
      genre: content.fieldGenre?.map((item) => item.id.toString()),
      genreFilterEnabled: content.fieldGenre?.length >= 1,
      entityId: content.id,
      limit: 500,
    },
    skip: type !== "act",
  });

  const {
    data: relatedEvents,
    loading: loadingEvents,
    error: errorEvents,
  } = useQuery(teaserNodeQueryRelatedEvents, {
    variables: {
      eventType: content.fieldEventType?.id.toString(),
      eventFilterEnabled: content.fieldEventType?.length >= 1,
      entityId: content.id,
      limit: 500,
    },
    skip: type !== "event",
  });

  useEffect(() => {
    if (type === "act" && relatedActs) {
      setRelatedContent(getRandomElements(relatedActs));
    } else if (type === "event" && relatedEvents) {
      setRelatedContent(getRandomElements(relatedEvents));
    }
  }, [relatedActs, relatedEvents, type]);

  if (relatedContent?.length === 0 && !loadingEvents && !loadingActs) return null;

  if (loadingEvents || loadingActs) {
    return (<LoadingIndicator/>);
  }

  if (relatedContent) {
    return (
      <div className="related-content" datatype={type}>
        <h3 className={`col-16 ${type === "event" ? 'headline-l' : 'headline-xl'}`}>
          <FormattedMessage id={"related"} />
        </h3>
        <div className="row teasers">
          {relatedContent.map((content, contentIndex) => (
            <React.Fragment key={contentIndex}>
              <TeaserParticipantGeneralRelated item={content} index={contentIndex} />
            </React.Fragment>
          ))}
        </div>
        {((mainSettings.fieldConferenceOverviewPage && type === "event") ||
          (mainSettings.fieldFestivalOverviewPage && type === "act")) && (
          <div className={"overview-link d-flex justify-content-center"}>
            <button className="btn btn-primary more">
              {type === "act" ? (
                <Link link={mainSettings.fieldFestivalOverviewPage} />
              ) : (
                <Link link={mainSettings.fieldConferenceOverviewPage} />
              )}
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default Related;