import React from "react";
import classNames from "classnames";
import ClickSuggestionsTopicsRBXCompany
  from "@js/microsites/rbx-company/general-components/paragraphs/click-suggestions-topics/click-suggestions-topics-rbx-company";
import {useSelector} from "react-redux";
import ClickSuggestionsTopics from "@paragraphs/click-suggestions-topics/click-suggestions-topics";
import PropTypes from "prop-types";

const ParagraphClickSuggestionsTopics = ({ content }) => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  const sectionClassNames = classNames({
    "paragraph paragraph-click-suggestions-topics": true,
  });

  return (
    <section
      className={sectionClassNames}
    >
      {microSite === "rbx-company" ? (
        <ClickSuggestionsTopicsRBXCompany
          content={content}
        />
      ) : (
        <ClickSuggestionsTopics
          content={content}
        />
      )}
    </section>
  );
};

ParagraphClickSuggestionsTopics.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldTopicsClickSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        fieldImage: PropTypes.object,
        fieldLink: PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.shape({
            routed: PropTypes.bool,
            path: PropTypes.string,
          }),
        })
      })
    ),
  }),
};

export default ParagraphClickSuggestionsTopics;
