import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronDown, ChevronUp } from "@carbon/icons-react";
import { FormattedMessage } from "react-intl";

const ParagraphList = ({ content }) => {
  const [open, setOpen] = useState(false);

  const sectionClassNames = classNames({
    "paragraph paragraph-list": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-15 offset-md-1">
            <div className="row">
              <div className="col-16 col-md-13">
                <h3>{content.fieldHeading}</h3>
              </div>
              {content.fieldCollapse && (
                <div className="col-16 col-md-3 d-md-flex justify-content-end align-items-baseline">
                  <div
                    className="btn btn-secondary btn-icon"
                    onClick={() => setOpen((prev) => !prev)}
                  >
                    {open ? (
                      <>
                        <FormattedMessage id="fold.in" /> <ChevronUp size={24} color="white" />
                      </>
                    ) : (
                      <>
                        <FormattedMessage id="fold.out" /> <ChevronDown size={24} color="white" />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              className={classNames({
                "row content": true,
                "d-none": !open,
                "d-flex": open || !content.fieldCollapse,
              })}
            >
              {content.fieldListElements.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className={classNames({
                      "list-item": true,
                      label: true,
                      "col-16 col-lg-4": true,
                    })}
                  >
                    <div
                      className="label"
                      dangerouslySetInnerHTML={{ __html: item.fieldLabel }}
                    />
                  </div>
                  <div
                    key={index}
                    className={classNames({
                      "list-item": true,
                      text: true,
                      "col-16 col-lg-10": true,
                    })}
                  >
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: item.fieldText }}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphList.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldCollapse: PropTypes.bool,
    fieldListElements: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ParagraphList;
