import React from "react";
import PropTypes from "prop-types";
import FieldTime from "../general-components/date-time/field-time";
import LanguageSwitch from "../i18n/language-switch";
import {useLocation} from "react-router-dom";

const TitleLanguageWrapper = (props) => {

  const location = useLocation();

  return (
    <div className="title-language-wrapper">
      <div className="spin-wrapper">
        {/* Display Node Title in Menu, if not frontpage. Title is dispatched in different Node Detail Pages */}
        {props.menuTitle && location?.pathname !== "/" ? (
          <h1 className="main-title">
            {props.menuTitle}
          </h1>
        ) : (
          <div className="title-and-date">
            <div className="spin-wrapper-inside">
              <h1 className="main-title">
                Reeperbahn Festival
              </h1>
              <div className="festival-date">
                {props.mainSettings.fieldFestivalTime &&
                  <>
                    <FieldTime
                      date={props.mainSettings.fieldFestivalTime.value}
                      timestamp={false}
                      format={"DD.-"}
                    />
                    <FieldTime
                      date={props.mainSettings.fieldFestivalTime.endValue}
                      timestamp={false}
                      format={"DD.MM.YYYY"}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        )}
        <LanguageSwitch />
      </div>
    </div>
  );
};

TitleLanguageWrapper.propTypes = {
  mainSettings: PropTypes.object,
  location: PropTypes.object,
  mainTitle: PropTypes.string,
};

export default TitleLanguageWrapper;