import React from "react";

function SpotifyButtonListen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="51.003"
      version="1.1"
      viewBox="0 0 12.965 13.495"
    >
      <g transform="translate(-107.436 -118.193)">
        <g fill="#000" transform="matrix(.26458 0 0 .26458 107.442 118.085)">
          <path
            fill="#000"
            d="M39.78.517L19.222 13.332H5.912A5.912 5.912 0 000 19.245v12.641c0 3.612 2.944 6.556 6.556 6.556h12.69l20.188 12.84c.47.222.965-.174.99-.743l.296-49.28c0-.593-.52-.99-.965-.766z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SpotifyButtonListen;
