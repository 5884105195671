  import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a hero-text paragraph.
 */
const ParagraphHeroText = ({ content }) => {
  /*
   *  IMPORTANT: Relies on Taxonomy terms to be flexible. Namings are:
   * Colors: red, purple
   * Sizes: standard, smaller, smallest
   * */
  const sectionClassNames = classNames({
    "paragraph paragraph-hero-text": true,
    [content.fieldColor?.name.toLowerCase()]: content.fieldColor,
    [content.fieldTextSize?.name.toLowerCase()]: content.fieldTextSize,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div
              className="hero text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ParagraphHeroText;
