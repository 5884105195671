import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import MediaStack from "@paragraphs/media-gallery/media-stack";
import StorySlider from "@paragraphs/media-gallery/story-slider";
import MediaGalleryProvider from "@paragraphs/media-gallery/store/media-gallery-provider";

const ParagraphMediaGallery = ({ content }) => {
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  if (microSite === "rbx-company") {
    return (
      <MediaGalleryProvider>
        <StorySlider
          content={content}
        />
      </MediaGalleryProvider>
    );
  } else {
    return(
      <MediaStack
        content={content}
      />
    );
  }
};

ParagraphMediaGallery.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default ParagraphMediaGallery;
