import React from "react";

import participantPageQuery from "../../participants/participant.graphql";
import Presenter from "../../participants/components/presenter";
import LookingFor from "../../participants/components/looking-for";
import SocialLinks from "../../participants/components/social-links";
import MetaInfos from "../../participants/components/meta-infos";
import Sponsors from "../../participants/components/sponsors";
import Appearances from "../shared-components/appearances/appearances";
import NodeWrapper from "@nodes/shared-components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import SpotifyWidget from "@js/participants/components/spotify-widget/spotify-widget";
import DetailPageIntro from "@nodes/shared-components/detail-page-intro";
import { useSelector } from "react-redux";
import ParagraphClickSuggestions from "@paragraphs/click-suggestions/paragraph-click-suggestions";

const Speaker = () => {
  const mainSettings = useSelector((reduxStore) => reduxStore.appStore.mainSettings),
    isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  const { entity, loading, error } = useNodeQuery(participantPageQuery);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity &&
        <>
          <DetailPageIntro
            content={entity}
            mode={"speaker"}
          />

          <div className="container-fluid main-infos speaker">
            <div className="row">
              <div className={"col-16 col-xl-4 meta-info-wrapper"}>
                {entity.fieldCompany &&
                  <div
                    className={"text body-xs"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldCompany }}
                  />
                }
                {entity.fieldPosition &&
                  <div
                    className={"text body-xs"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldPosition }}
                  />
                }
                {entity.fieldCountry &&
                  <div className="tag black body-xxs d-inline-flex">
                    {entity.fieldCountry.value}
                  </div>
                }
              </div>

              <div className={"col-16 col-xl-12 appearances-wrapper"}>
                <Appearances
                  detailedMode={true}
                  content={entity}
                  type={"speaker"}
                  showAddToCalendar={true}
                  showEndDate={true}
                />
              </div>
            </div>
          </div>

          <div className="container max-width main-container">
            <div className="row with-gaps">
              {entity.fieldBiography &&
                <div className="col-16 text-wrapper">
                  <div
                    className={"text headline-subhead"}
                    dangerouslySetInnerHTML={{ __html: entity.fieldBiography }}
                  />
                </div>
              }

              <div className="col-16 col-lg-8 left-col">

                <SpotifyWidget
                  content={entity}
                />

                <LookingFor
                  content={entity}
                />

                <Sponsors
                  content={entity}
                />

              </div>

              <div className="col-16 col-lg-8 right-col">
                <Presenter
                  content={entity}
                />

                <SocialLinks
                  content={entity}
                />
              </div>
              {mainSettings && mainSettings.fieldBackToSpeakersCta?.list && mainSettings.fieldBackToSpeakersCta.list[0] &&
                <div className="back-to-overview">
                  <ParagraphClickSuggestions
                    content={mainSettings.fieldBackToSpeakersCta.list[0]?.entity}
                    forceSameTab={true}
                  />
                </div>
              }
            </div>
          </div>
        </>
      }
    </NodeWrapper>
  );
};

Speaker.propTypes = {};

export default Speaker;
