import { useContext } from "react";
import { PageTransitionContext } from "./page-transition-provider";

/**
 * Custom hook to access the overview context.
 * @returns {Object} The overview context object.
 */
const usePageTransitionContext = () => {
  const pageTransitionContext = useContext(PageTransitionContext);
  return pageTransitionContext;
};

export default usePageTransitionContext;
