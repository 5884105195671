import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Create the overview context.
export const MediaGalleryContext = React.createContext({});


const MediaGalleryProvider = ({ children, content }) => {
  const [swiperComponents, setSwiperComponents] = useState({ Swiper: null, SwiperSlide: null });
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const [swiperRef, setSwiperRef] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperModules, setSwiperModules] = useState({ Navigation: null, Pagination: null });

  useEffect(() => {
    const loadSwiper = async () => {
      try {
        const { Swiper, SwiperSlide } = await import('swiper/react');
        const { Navigation, Pagination, Autoplay } = await import('swiper/modules');
        await import('swiper/css');
        await import('swiper/css/navigation');

        setSwiperComponents({ Swiper, SwiperSlide });
        setSwiperModules({ Navigation, Pagination, Autoplay });
        setIsCssLoaded(true);
        console.log("Swiper, modules and CSS loaded");
      } catch (err) {
        console.error("Error importing Swiper, modules or CSS:", err);
      }
    };

    loadSwiper();
  }, []);

  const { Swiper, SwiperSlide } = swiperComponents;
  const { Navigation, Pagination, Autoplay } = swiperModules;

  // The context value provides all necessary values and functions.
  const value = {
    ...content,
    setSwiperComponents,
    swiperComponents,
    setSwiperModules,
    swiperModules,
    setIsCssLoaded,
    isCssLoaded,
    Swiper,
    SwiperSlide,
    Navigation,
    Pagination,
    Autoplay,
    setSwiperRef,
    swiperRef,
    setActiveIndex,
    activeIndex
  };

  return (
    <MediaGalleryContext.Provider value={value}>
      {children}
    </MediaGalleryContext.Provider>
  );
};

MediaGalleryProvider.propTypes = {
  children: PropTypes.node
};

export default MediaGalleryProvider;
