import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { pagerFullPageContextAction } from "../../../app-actions";
import Tags from "../../tags/tags";

const TeaserPerson = ({ item, pagerFullPage = false }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <article className="node node-teaser teaser-person">
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link
        onClick={() => dispatch(pagerFullPageContextAction(pagerFullPage))}
        className="flex-wrap"
        to={item.url ? item.url.path : item.path.alias}
      >
        {item.fieldImage?.first?.entity?.fieldMediaImage && (
          <Image
            data={item.fieldImage.first.entity.fieldMediaImage}
            nodeTitle={item.title}
          />
        )}
        <h3>{item.title}</h3>
        <Tags tags={item.fieldTags} />
      </Link>
    </article>
  );
};

export const teaserPersonPropTypes = PropTypes.shape({
  id: PropTypes.string,
  activeBefore: PropTypes.bool,
  title: PropTypes.string,
  fieldEmail: PropTypes.string,
  fieldPhone: PropTypes.string,
  fieldMobile: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldText: PropTypes.string,
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    first: PropTypes.shape({
      // customCaption: PropTypes.string,
      // defaultCaption: PropTypes.bool,
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
});

TeaserPerson.propTypes = {
  item: teaserPersonPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserPerson;
