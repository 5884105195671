import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import MediaSwitch from "../../../media-switch";
import LoadingIndicator from "@general-components/loading-indicator";
import useMediaGalleryContext from "./store/use-media-gallery-context";

const StorySlider = ({ content }) => {
  const swiperRefLocal = useRef();
  const { Swiper, SwiperSlide, Navigation, Pagination, isCssLoaded, Autoplay, setSwiperRef, setActiveIndex, activeIndex } = useMediaGalleryContext();

  /* This Function Makes sure that every slide with a video automatically pauses the autoplay functionality.
  *  It Should reset the autoplay Status if the video is ended or another slide without a video is shown.
  */
  const videoAutoPlayHandler = () => {
    if (content.fieldMultimediaRawField.list[activeIndex]?.entity.entityBundle === "video") {
      swiperRefLocal?.current?.swiper?.autoplay?.stop();
    } else {
      swiperRefLocal?.current?.swiper?.autoplay?.start();
    }
  }

  useEffect(() => {
    videoAutoPlayHandler();
  }, [activeIndex]);

  if (!Swiper || !SwiperSlide || !Navigation || !Pagination || !isCssLoaded) {
    return <LoadingIndicator fullPage={true}/>; // Or some other loading indicator
  }

  if (swiperRefLocal?.current && setSwiperRef) {
    setSwiperRef(swiperRefLocal);
  }

  return (
    <section
      className={classNames({
        "paragraph-media-gallery": true,
        "story-slider": true
      })}
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides
        loop={true}
        navigation={{ enabled: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // Save roccect active Index in Style. RealIndex recognizes looped slides.
        onRealIndexChange={(element) =>{
          setActiveIndex(element.realIndex);
        }}
        modules={[Pagination, Navigation, Autoplay]}
        pagination={{
          clickable: true,
        }}
        ref={swiperRefLocal}
      >
        {content.fieldMultimediaRawField?.list?.map((item, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => {
              return (
                <div
                  className={classNames({
                    "inner-slide": true
                  })}
                  data-id={index}
                  key={index}
                >
                  <MediaSwitch
                    media={item.entity}
                    irem={item.entity}
                    autoplay={isActive}
                    forcePlay={isActive}
                    loop={false}
                  />
                </div>
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

StorySlider.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldMultimediaRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldCaption: PropTypes.string,
            fieldCredits: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
              }),
            }),
          }),
        })
      ),
    }),
  }),
};

export default StorySlider;