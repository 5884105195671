import React from "react";
import {useSelector} from "react-redux";
import FieldTime from "@general-components/date-time/field-time";
import DateWrapper from "@js/participants/components/date-wrapper";
import LocationPinBlack from "@/assets/ui/location-pin-black.svg";
import {Link} from "react-router-dom";
import {AddToCalendar} from "@general-components/add-to-calendar";

const AppearanceDetailed = ({ content, appearance, type, showAddToCalendar, viewMode, appearanceId, showEndDate }) => {

  const releaseState = useSelector((reduxStore) => reduxStore.appStore.releaseState);

  return(
    <article className="appearance detailed">
      <div className="container no-padding">
        <div className="row">
          {(type === "event" || type === "act") &&
            <div className={`col type body-xs make-headline-light ${appearanceId === 0 ? "" : "d-none d-lg-flex"}`}>
              {/* Only show label on first occasion */}
              {appearanceId === 0 &&
                <>
                  {/* All Acts are Concerts */}
                  {type === "act" &&
                    <>
                      Concert
                    </>
                  }

                  {/* Events are having different types */}
                  {type === "event" && content?.fieldEventType &&
                    <>
                      {content.fieldEventType?.name}
                    </>
                  }
                </>
              }
            </div>
          }
          {type === "speaker" && appearance.fieldHappening &&
            <Link
              to={appearance.fieldHappening?.url.path}
              className="col event-name body-xs make-headline-light"
            >
              {appearance.fieldHappening.label}
            </Link>
          }

          <div className="col time d-flex body-xs">
            {releaseState.showDay &&
              <>
                <div className="d-flex align-items-start make-headline-light">
                  <FieldTime
                    timestamp={true}
                    date={appearance.fieldDateRawField.first.value}
                    format={"dddd"}
                    dependsOnReleaseState={true}
                  />
                </div>
                <DateWrapper
                  content={appearance}
                  viewMode={"time"}
                  showEndDate={showEndDate}
                />
              </>
            }
          </div>

          <div className="col venue d-flex flex-row icon-flex-wrap">
            {appearance.fieldVenueLocation &&
              <>
                <img
                  alt={"Location Pin"}
                  className={"icon location-pin black"}
                  src={LocationPinBlack}
                />
                {viewMode === "detail" ? (
                  <Link
                    to={appearance.fieldVenueLocation.url.path}
                    className="venue-name body-xs action-element"
                  >
                    {appearance.fieldVenueLocation.label}
                  </Link>
                ) : (
                  <span
                    className="venue-name body-xs"
                  >
                    {appearance.fieldVenueLocation.label}
                  </span>
                )}
              </>
            }
          </div>
          {showAddToCalendar &&
            <div className="col atc d-flex flex-row icon-flex-wrap">
              {showAddToCalendar &&
                <AddToCalendar
                  content={content}
                  appearance={appearance}
                />
              }
            </div>
          }
        </div>
        {type === "linked-event" &&
          <div className="col name d-flex body-xs make-headline-light">
            {content?.label}
          </div>
        }
      </div>
    </article>
  );
};

export default AppearanceDetailed;
