import React from "react";
import PropTypes from "prop-types";
import Image from "../../../image/image";
import classNames from "classnames";

const ClickSuggestionTopic = ({ content }) => {

  const articleClassNames = classNames({
    "click-suggestion": true,
    "with-image": content.fieldImage,
    "no-image": !content.fieldImage
  });

  return(
      <article className={articleClassNames}>

        {/* @todo: put this in a props driven, own component */}
        <div className={"dashed-corner top left smaller"} />
        <div className={"dashed-corner top right smaller"} />
        <div className={"dashed-corner bottom left smaller"} />
        <div className={"dashed-corner bottom right smaller"} />

        <div className="inner-wrapper">
          {content.fieldImage ? (
            <div className="background-image">
              <Image
                data={content.fieldImage.fieldMediaImage}
              />
            </div>
          ) : (
            <div>FARBWRAPPER</div>
          )}
          <div className="text-wrapper">
              <span className="headline headline-s">
                {content?.fieldLink && (
                  <>{content.fieldLink.title}</>
                )}
              </span>
          </div>
        </div>
      </article>
    );
}

ClickSuggestionTopic.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.object,
    fieldLink: PropTypes.shape({
      url: PropTypes.shape({
        routed: PropTypes.bool,
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ClickSuggestionTopic;