import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ArrowRight from "@/assets/rbx-company/arrow-right.svg";

const MergedWords = ({ words, type = "solid", withArrow }) => {
  const mainClassNames = classNames({
    'merged-words': true,
    [type]: true
  });

  words = words.split(" ");

  return (
    <div className={mainClassNames}>
      {words && words.map((item, index) => {
        const tagClassNames = classNames({
          'big-radius': index % 2 === 0,
          'small-radius': index % 2 !== 0,
          word: true
        });

        return (
          <div
            className={tagClassNames}
            key={`${item}-${index}`}
          >
          {item}
        </div>
        );
      })}
      {withArrow &&
        <div className="word">
          <img
            src={ArrowRight}
            alt={"arrow right"}
            className="arrow"
          />
        </div>
      }
    </div>
  );
};

MergedWords.propTypes = {
  words: PropTypes.array,
  type: PropTypes.oneOf(["solid", "border"]),
};

export default MergedWords;