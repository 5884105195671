import React from "react";
import PropTypes from "prop-types";
import { encodeUrl } from "../../../lib/encode-url";
import Image from "../../image/image";

const TeaserVenueImage = (props) => {
  return (
    <article
      className="venue-image"
      data-to-scrollspy-id={encodeUrl(props.item.label)}
    >
      {props.item.fieldImage && (
        <Image
          data={props.item.fieldImage.fieldMediaImage}
          nodeTitle={`Location: ${props.item.label}`}
        />
      )}
    </article>
  );
};

TeaserVenueImage.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TeaserVenueImage;
