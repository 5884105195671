import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import {useDispatch, useSelector} from "react-redux";

import menuQuery from "../general-components/navigation/menu-query.graphql";
import { Menu } from "@general-components/navigation/menu";
import useConfigQuery from "../hooks/use-config-query/use-config-query";
import LoadingIndicator from "../general-components/loading-indicator";
import Error from "../system/error";
import Link from "../general-components/link/link";
import LogoButton from "../general-components/logo-button";
import AccessibilityMenu from "./accessibility-menu";
import {
  mainMenuOpenAction,
  accessibilityMenuOpenAction,
  mainSettingsAction,
  releaseStateAction
} from "../app-actions";
import TitleLanguageWrapper from "./title-language-wrapper";
import { setReleaseState } from "@lib/setReleaseState";
import {waitForElm} from "@lib/wait-for-elm";
import {useLocation} from "react-router-dom";
import ViewSwitch from "@paragraphs/overview/components/main-controls/view-switch";
import CustomAnimation from "@general-components/custom-animation";

const SimpleNavigation = (props) => {
  //@todo: split this into several components and use ContextAPI.

  const currentLanguage = useSelector((reduxStore) => reduxStore.i18n.currentLanguage);
  const microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);
  const mainSettings = useSelector((reduxStore) => reduxStore.appStore.mainSettings);
  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);
  const mainMenuOpen = useSelector((reduxStore) => reduxStore.appStore.mainMenuOpen);
  const accessibilityMenuOpen = useSelector((reduxStore) => reduxStore.appStore.accessibilityMenuOpen);
  const persistViewSwitch = useSelector((reduxStore) => reduxStore.appStore.persistViewSwitch);

  const location = useLocation();

  const mainMenuName = microSite === "rbx-company" ? "RBX_MENU" : "MAIN";
  const dispatch = useDispatch();

  const nav = useRef(),
    menuCTA = useRef();

  const { data: mainSettingsData, loading, error } = useConfigQuery(["main_settings"]);

  const ctaScroll = () => {
    if (isMobile && menuCTA.current) {
      if ((document.body.getBoundingClientRect().top * -1) > (window.innerHeight * 1.5)) {
        menuCTA?.current?.classList.add("show");
      } else {
        menuCTA?.current?.classList.remove("show");
      }
    }
  }

  useEffect(() => {
    if (mainSettingsData && !microSite) {
      dispatch(mainSettingsAction(mainSettingsData));

      // Get Release State from Main Settings and set the State depending on selected Option
      dispatch(releaseStateAction(setReleaseState(mainSettingsData)));
    }
  }, [mainSettingsData, microSite, dispatch]);

  useEffect(() => {
    // Close Menus on route change
    if (mainMenuOpen || accessibilityMenuOpen) {
      dispatch(mainMenuOpenAction(false));
      dispatch(accessibilityMenuOpenAction(false));
    }
  }, [location.pathname]);

  useEffect(() => {
    // Determine Menu Height for a smooth transition
    if (microSite === "rbx-company") {
      waitForElm(
        "#overlay-menu ul",
        nav.current
      ).then((elm) => {
        document.body.style.setProperty(
          "--overlay-menu-height",
          elm.getBoundingClientRect().height +
          "px"
        );
      });
    }
  }, []);

  useEffect(() => {
    if (mainSettings.fieldMenuCta) {
      window.addEventListener('scroll', ctaScroll);
    }
  }, [mainSettings.fieldMenuCta]);

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const toggleOverlayMenu = () => {
    dispatch(mainMenuOpenAction(!mainMenuOpen));
    dispatch(accessibilityMenuOpenAction(false));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      toggleOverlayMenu();
    }
  };

  return (
    <>
      {mainSettings.fieldMenuCta && !microSite && (
        <div className="menu-cta" ref={menuCTA}>
          <div className="inner-wrapper header-element">
            <div className="border-element" />
            <div className="effect-element" />
            <Link className="learn-more" link={mainSettings.fieldMenuCta}/>
          </div>
        </div>
      )}

      <div
        className={`header-element ${mainMenuOpen ? "active" : ""}`}
        language={currentLanguage}
        ref={nav}
      >
        <div className="container">
          <div className="border-element" />
          <div className="row">
            {!microSite && (
              <>
                <LogoButton />
                <TitleLanguageWrapper
                  menuTitle={props.menuTitle}
                  mainSettings={mainSettings}
                />
              </>
            )}
            <div className="button-wrapper">
              {currentLanguage !== "en" && !microSite && <AccessibilityMenu />}
              {/* Menu Toggle */}
              <div
                id="toggle-overlay-menu"
                tabIndex={0}
                className={mainMenuOpen ? "active" : ""}
                aria-label="toggle overlay menu"
                onClick={() => toggleOverlayMenu()}
                onKeyDown={() => handleKeyDown()}
              >
                {microSite === "rbx-company" ? (
                  <>
                    <div className="bar"/>
                    <div className="bar"/>
                  </>
                ) : (
                  <div className="spin-wrapper">
                    <span>Menu</span>
                    <span>Close</span>
                  </div>
                )}
              </div>

              {/* Overlay Menu */}
              <div id="overlay-menu" className={mainMenuOpen ? "active" : ""}>
                <div className="nav-wrap">
                  <div className="upper-part header-element">
                    <div className="border-element" />
                    <div className="container">
                      <div className="row">
                        <div className="col-16 col-md-custom-big">
                          <Menu
                            navClassName="main-menu-desktop"
                            menuName={mainMenuName}
                            ariaLabel={"Main Menu"}
                            showBorderElement={false}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="effect-element" /> */}
                  </div>
                  {!microSite && (
                    <div className="bottom-part">
                      <div className="container">
                        <div className="row">
                          <div className="col-16">
                            <Menu
                              menuName="HIGHLIGHT_MENU"
                              ariaLabel={"Main Menu"}
                              showBorderElement={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!microSite && <div className="effect-element" />}
        </div>
      </div>

      <CustomAnimation
        type={"appear"}
        isVisible={location.pathname === mainSettings?.fieldConferenceOverviewPage?.uri?.path || persistViewSwitch}
      >
        <ViewSwitch/>
      </CustomAnimation>
    </>
  );
};

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

SimpleNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default compose(
  graphql(menuQuery, {
    options: () => ({
      variables: {
        name: "MAIN",
        /*language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",*/
      },
    }),
    name: "mainMenuQuery",
  })
)(SimpleNavigation);