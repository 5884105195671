import React from "react";
import PropTypes from "prop-types";

export const SocialMedia = ({ content }) => {
  return (
    <div className="col-16 social-media">
      {content.fieldFacebook && (
        <a
          className="facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFacebook.uri.path}
        >
          Facebook
        </a>
      )}
      {content.fieldYoutube && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldYoutube.uri.path}
        >
          YouTube
        </a>
      )}
      {content.fieldFlickr && (
        <a
          className="flickr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldFlickr.uri.path}
        >
          Flickr
        </a>
      )}
      {content.fieldInstagram && (
        <a
          className="instagram"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldInstagram.uri.path}
        >
          Instagram
        </a>
      )}
      {content.fieldVimeo && (
        <a
          className="vimeo"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldVimeo.uri.path}
        >
          Vimeo
        </a>
      )}
      {content.fieldLinkedin && (
        <a
          className="linkedin"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldLinkedin.uri.path}
        >
          LinkedIn
        </a>
      )}
      {content.fieldPinterest && (
        <a
          className="pinterest"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldPinterest.uri.path}
        >
          Pinterest
        </a>
      )}
      {content.fieldX && (
        <a
          className="x"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldX.uri.path}
        >
          X
        </a>
      )}
      {content.fieldXing && (
        <a
          className="xing"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldXing.uri.path}
        >
          Xing
        </a>
      )}
      {content.fieldTumblr && (
        <a
          className="tumblr"
          target="_blank"
          rel="noopener noreferrer"
          href={content.fieldTumblr.uri.path}
        >
          Tumblr
        </a>
      )}
    </div>
  );
};

SocialMedia.propTypes = {
  content: PropTypes.shape({
    fieldFacebook: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldX: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};
