import React from "react";
import ErrorBoundary from "../../error-boundary";
import FieldTime from "../../general-components/date-time/field-time";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";

const DateWrapper = ({ content, viewMode, showEndDate }) => {
  const releaseState = useSelector((reduxStore) => reduxStore.appStore.releaseState);

  const dateValue = content.fieldDateRawField.first;

  const date = {
    value: dateValue.value,
    endValue: showEndDate && dateValue.endValue,
  };


  if (date.value && (releaseState.showDay || releaseState.showDate)) {
    return (
      <ErrorBoundary>
        <div className="date-wrapper">
          {(releaseState.showDay || releaseState.showDate) && viewMode !== "time" &&
            <div className="day">
              <FieldTime
                date={date.value}
                timestamp={true}
                format={"dd"}
                className="day"
              />
            </div>
          }
          {/* Dates are set as 12:00AM when the days are known, but no times are set. IF this happens, there should no time be visible. */}
          {releaseState.showDate && (
            <div className="time d-flex flex-wrap">
              <FieldTime
                date={date.value}
                timestamp={true}
                format={"HH.mm"}
                className="time"
              />
              {date.endValue && date.value !== date.endValue ? (
                <>-
                  <FieldTime
                    date={date.endValue}
                    timestamp={true}
                    format={"HH.mm"}
                    className="time"
                  />
                  <div className="time-suffix">
                    <FormattedMessage id={"time.suffix"}/>
                  </div>
                </>
              ) : (
                <div className="time-suffix">
                  <FormattedMessage id={"time.suffix"}/>
                </div>
              )}
            </div>
          )}
        </div>
      </ErrorBoundary>
    );
  } else {
    return false;
  }
};

export default DateWrapper;
