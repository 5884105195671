import React from "react";
import PropTypes from "prop-types";
import Video from "./components/video";
import classNames from "classnames";

const ParagraphVideo = ({ content }) => {
  const sectionClassNames = classNames({
      "paragraph paragraph-video": true,
      "small": !content.fieldBig,
      "big": content.fieldBig,
      "highlighted": content.fieldHighlighted,
    }),
    infoClassNames = classNames({
    "info-wrapper col-16": true,
    "col-md-8": true
    }),
    rowClassNames = classNames({
    "row": true,
    "left": !content.fieldBig && content.fieldOrientation !== "right",
    "right": !content.fieldBig && content.fieldOrientation === "right"
  }),
    mediaClassNames = classNames({
      "media-wrapper col-16": true,
      "col-md-8": !content.fieldBig
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className={rowClassNames}>
          <div className={mediaClassNames}>
            {content.fieldVideoFile && content.fieldImage &&
              <Video
                content={content.fieldVideoFile}
                customThumbnail={content.fieldImage.fieldMediaImage}
              />
            }
          </div>
          <div
            className={infoClassNames}
          >
            <div className="scroll-wrapper">
              <div className="text-wrapper">
                {content.fieldSubline && (
                  <h3 className="subline">{content.fieldSubline}</h3>
                )}
                {content.fieldHeading && (
                  <h2 className="headline">{content.fieldHeading}</h2>
                )}
                {content.fieldText && (
                  <div
                    tabIndex={0}
                    className="text allow-scrolling"
                    dangerouslySetInnerHTML={{
                      __html: content.fieldText,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const videoThumbnailPropTypes = {
  alt: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.shape({
    urlPath: PropTypes.string,
  }),
}

export const videoFilePropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  mediaFileUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
}

export const videoEmbedPropTypes = {
  thumbnail: PropTypes.shape(videoThumbnailPropTypes),
  fieldMediaOembedVideo: PropTypes.string,
}

ParagraphVideo.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    /* fieldVideoFile returns either type MediaEmbeddedVideo or MediaVideo */
    fieldVideoFile: PropTypes.oneOf([

      /* Video File */
      PropTypes.shape(videoFilePropTypes),

      /* Embedded Video (YouTube/Vimeo) */
      PropTypes.shape(videoEmbedPropTypes),
    ]),
  }),
};

export default ParagraphVideo;
