import React from "react";

const MetaInfos = ({content}) => {
  return (
    <div className="meta-infos">
      {/*content.fieldParticipantType && content.fieldParticipantType !== "act" &&
        <div className="meta-info concert body-xs">
          {content.fieldParticipantType?.capitalize()}
        </div>
      */}

      {content.fieldMood &&
        <div className="meta-info mood body-xs">
          {content.fieldMood.name}
        </div>
      }

      {content.fieldGenre && content.fieldGenre.map((genre, genreId) =>
        <div className="meta-info genre body-xs" key={genreId}>
          {genre.name}
        </div>
      )}

      {content.fieldCountry &&
        <div className="meta-info country body-xs">
          {content.fieldCountry.value}
        </div>
      }
    </div>
  );
};

export default MetaInfos;
