import { useEffect } from "react";

function useDebouncedEffect(fn, deps, time) {
  const dependencies = [...(deps || []), time];

  useEffect(() => {
    const timeout = setTimeout(fn, time);
    return () => {
      clearTimeout(timeout);
    };
  }, dependencies);
}

export default useDebouncedEffect;
