import React, { Component, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Scrollspy from "react-scrollspy";
import { encodeUrl } from "../../../../lib/encode-url";
import { changeActiveImage } from "../../../../lib/change-active-image";
import ErrorBoundary from "../../../../error-boundary";
import BreakBanner from "./components/break-banner";
import TeaserScrollOverviewContent from "./components/teaser-scroll-overview-content";

import {connect, useSelector} from "react-redux";
import { waitForElm } from "../../../../lib/wait-for-elm";
import transformNodesToNodeImageLinks from "./components/transform-nodes-to-node-image-links";
import classNames from "classnames";

const ScrollOverview = ({ nodes, showBanners, type, clickBehavior, dayFilterEnabled, updateUrl, displayType, overviewType }) => {
  const scrollOverview = useRef(),
    [scrollOffset, setScrollOffset] = useState(50),
    [programmeLoaded, setProgrammeLoaded] = useState(false);

  const mainSettings = useSelector((reduxStore) => reduxStore.appStore.mainSettings);
  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  // The used Module needs an array with all anchors. However, we cannot just loop through the nodes, since whe intersect them with the participant breaks.
  const getScrollAnchors = (nodes) => {
    let anchors = [],
      breaks = mainSettings?.fieldParticipantBreaks;

    if (type === "conference") {
      breaks = mainSettings?.fieldConferenceBreaks;
    }
    nodes.map((item, index) => {
      anchors.push(
        encodeUrl(item.title)
      );
      if (showBanners) {
        if ((index + 1) % 10 === 0) {
          if (breaks[(index + 1) / 10 - 1]) {
            anchors.push(
              `${encodeUrl(
                breaks[(index + 1) / 10 - 1].entity.fieldHeading
              )}-${index}`
            );
          }
        }
      }
    });

    return anchors;
  };

  const scrollListener = () => {
    if (window.scrollY <= 50) {
      this.setState({
        scrollOffset: 50,
      });
    }
    if (window.scrollY >= 50 && scrollOffset === 50) {
      this.setState({
        scrollOffset: -1 * (window.innerHeight / 2 - 100),
      });
    }
  };

  useEffect(() => {
    if (!programmeLoaded) {
      waitForElm(".participant-image img", scrollOverview.current).then(
        (elm) => {
          setTimeout(() => {
            setProgrammeLoaded(true);
          }, 150);
        }
      );
    }
    if (updateUrl) {
      window.addEventListener("scroll", () => scrollListener());
    }
  }, []);

  // Nodes will be filled with Participant Breaks if neeeded and prepared as Links for Scrollspy
  let nodeImageLinks = transformNodesToNodeImageLinks({nodes, type, showBanners});

  const overviewClassNames = classNames({
    'scroll-overview': true,
    'loaded': programmeLoaded,
    'loading': !programmeLoaded
  });

  return(
    <div
      className={overviewClassNames}
      ref={scrollOverview}
    >
      {/*<LoadingIndicator fullpage={true}/>*/}
      {/* Lines shown in list view */}
      <div className="line top" />
      {nodeImageLinks && nodes && (
        <div className="container content">
          {isMobile ? (
            <>
              {nodes.map((item, index) => (
                <React.Fragment key={index}>
                  <ErrorBoundary>
                    <TeaserScrollOverviewContent
                      item={item}
                      index={index}
                      updateUrl={true}
                      overviewType={overviewType}
                      dayFilterEnabled={dayFilterEnabled}
                      pagerFullPage={false}
                    />
                  </ErrorBoundary>
                  {/*
                   * Load a Break Banner every 10th participant
                   */}
                  {showBanners && (index + 1) % 10 === 0 && (
                    <>
                      {breaks && breaks[(index + 1) / 10 - 1] && (
                        <div
                          className="col-16 break-teaser"
                          data-to-scrollspy-id={`${encodeUrl(
                            breaks[(index + 1) / 10 - 1].entity.fieldHeading
                          )}-${index}`}
                        >
                          <h2>
                            {breaks[(index + 1) / 10 - 1].entity.fieldHeading}
                          </h2>
                          {isMobile &&
                            breaks &&
                            breaks[(index + 1) / 10 - 1] && (
                              <BreakBanner
                                content={breaks[(index + 1) / 10 - 1].entity}
                                id={index}
                                type={type}
                              />
                            )}
                        </div>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </>
          ) : (
            <div className={`row ${displayType}`}>
              <div className="col-8 image-wrapper">
                {nodes.length > 1 ? (
                  <div className="absolute-wrapper">
                    <div className="sticky-wrapper">
                      <div className="movement-wrapper">
                        <Scrollspy
                          //updateHistoryStack={false}
                          // offsetTop Does not work, no idea why. Therefore i am using a negative offsetBottom
                          //offsetBottom={-300}
                          //offsetTop={500}
                          //useBoxMethod={true}
                          //onUpdateCallback={(id) => changeActiveImage(this.scrollContainer.current, id)}
                          offset={
                            typeof window !== "undefined"
                              ? scrollOffset
                              : -600
                          }
                          items={getScrollAnchors(nodes)}
                          componentTag={"div"}
                          currentClassName="is-current"
                          onUpdate={(el) => {
                            if (updateUrl) {
                              if (window.scrollY <= 100) {
                                setScrollOffset(50);
                              } else {
                                setScrollOffset(-1 * (window.innerHeight / 2 - 100));
                              }
                            } else if (!updateUrl) {
                              // if teaserlist, not programme overview
                              setScrollOffset(-1 * (window.innerHeight / 2 - 100));
                            }
                            changeActiveImage(
                              el,
                              scrollOverview.current
                            );
                          }}
                        >
                          {nodeImageLinks}
                        </Scrollspy>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="single-result">{nodeImageLinks}</div>
                )}
              </div>
              <div className="col-8 teaser-wrapper">
                <div className="scroll-wrapper">
                  {nodes.map((item, index) => (
                    <React.Fragment key={index}>
                      <ErrorBoundary>
                        <TeaserScrollOverviewContent
                          item={item}
                          index={index}
                          updateUrl={updateUrl}
                          pagerFullPage={false}
                          clickBehaviour={clickBehavior}
                        />
                      </ErrorBoundary>
                      {/*
                       * Load a Break Banner every 10th participant
                       */}
                      {showBanners && (index + 1) % 10 === 0 && (
                        <>
                          {breaks && breaks[(index + 1) / 10 - 1] && (
                            <div
                              className="break-teaser programme-element"
                              id={`${encodeUrl(
                                breaks[(index + 1) / 10 - 1].entity
                                  .fieldHeading
                              )}-${index}`}
                            >
                              <div
                                className="col-16 break-teaser"
                                data-to-scrollspy-id={`${encodeUrl(breaks[(index + 1) / 10 - 1].entity.fieldHeading)}-${index}`}
                              >
                                <h2>
                                  {breaks[(index + 1) / 10 - 1].entity.fieldHeading}
                                </h2>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="line bottom" />
    </div>
  );

}

ScrollOverview.propTypes = {
  mainSettings: PropTypes.oneOfType([PropTypes.bool]),
  type: PropTypes.oneOf([
    "conference",
    "participant",
    "ScrollOverview",
    "programme",
  ]),
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.object,
      }),
    }),
    fieldPagerAufVollseiten: PropTypes.bool,
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.string,
  }),
  overviewType: PropTypes.string,
  clickBehavior: PropTypes.string,
  displayType: PropTypes.string,
  isMobile: PropTypes.bool,
  nodes: PropTypes.array,
  skipQuery: PropTypes.bool,
  showBanners: PropTypes.bool,
  updateUrl: PropTypes.bool,
  manualNodes: PropTypes.array,
};

export default ScrollOverview;
