import { useContext } from "react";
import { MediaGalleryContext } from "./media-gallery-provider";

/**
 * Custom hook to access the overview context.
 * @returns {Object} The overview context object.
 */
const useMediaGalleryContext = () => {
  const mediaGalleryContext = useContext(MediaGalleryContext);
  return mediaGalleryContext;
};

export default useMediaGalleryContext;
