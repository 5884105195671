import React from "react";
import ErrorBoundary from "../../../../error-boundary";
import DateWrapper from "@js/participants/components/date-wrapper";
import TimetableAccessibilityInfos from "@teasers/appearance/components/timetable-accessibility-infos";
import {useSelector} from "react-redux";

const TimetableMetaInfos = ({ content, type }) => {
  const happeningContent = content.fieldHappening,
    appearanceContent = content,
    isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  return (
    <ErrorBoundary>
      <div className="timetable-meta-infos">
        <DateWrapper
          content={content}
          showEndDate={true}
        />
        {appearanceContent.fieldVenueLocation && (
          <p className="venue body-xs make-headline-light mb-0">
            {appearanceContent.fieldVenueLocation.label}
          </p>
        )}
        {type && <div className="event-type dot-before">{type}</div>}

        {!isMobile &&
          <TimetableAccessibilityInfos
            content={content}
          />
        }
      </div>
    </ErrorBoundary>
  );
};

export default TimetableMetaInfos;
