import React, { useEffect, useState, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import debounce from "lodash/debounce";

const Controls = ({ children }) => {
  // Threshold for scroll speed to trigger panic-scroll class
  const scrollSpeedThreshold = 5;
  // Time in milliseconds to wait before removing panic-scroll class
  const scrollDebounceTime = 2000;

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [lastTime, setLastTime] = useState(Date.now());
  const [panicScroll, setPanicScroll] = useState(false);

  const mainControls = useRef();

  useEffect(() => {

    // cleanup on unmount
    return () => {
      document?.body.classList.remove("panic-scroll");
      document.getElementById("site-header")?.classList.remove("vanish");
    };
  }, []);

  /**
   * Set the panicScroll state to false for use as condition in handlePanicScroll function.
   */
  const resetScrollProcess = useCallback(() => {
    setPanicScroll(false);
  }, [panicScroll]);

  /**
   * Debounce function which is triggered when the user stops scrolling for 2 seconds.
   * It will remove the panic-scroll class from the body and set the panicScroll state to false.
   * useCallback is used to prevent the function from being recreated on every render.
   */
  const debounceScrollEnd = useCallback(
    debounce(resetScrollProcess, scrollDebounceTime, {}),
    [panicScroll]
  );

  const handlePanicScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;
    const currentTime = Date.now();

    const menu = document.getElementById("site-header");
    const offset = 150;

    const scrollPositionIsBelowFilters =
      mainControls.current.getBoundingClientRect().top - offset <=
      parseInt(window.getComputedStyle(mainControls.current).top);

    // Calculate speed of scroll (pixels since last scroll event / time since last scroll event)
    const speed = (lastScrollTop - scrollTop) / (currentTime - lastTime);

    if (!panicScroll) {
      // Threshold (positive value for upwards scroll, negative value for downwards scroll)
      if (speed > scrollSpeedThreshold) {
        document?.body.classList.add("panic-scroll");
        menu.classList.remove("vanish");
        // Set panicScroll state to true to prevent multiple calls to the panic function
        setPanicScroll(true);
      } else {
        document?.body.classList.remove("panic-scroll");
        if (scrollPositionIsBelowFilters) {
          menu.classList.add("vanish");
        } else {
          menu.classList.remove("vanish");
        }
      }
    }

    // Set last scroll position and time for next scroll event
    setLastScrollTop(scrollTop);
    setLastTime(currentTime);

    debounceScrollEnd();
  }, [lastScrollTop, lastTime, panicScroll, debounceScrollEnd]);

  useEffect(() => {
    window.addEventListener("scroll", handlePanicScroll);
    return () => {
      window.removeEventListener("scroll", handlePanicScroll)
    };
  }, [handlePanicScroll]);

  return (
    <div className="main-controls row" ref={mainControls}>
      {children}
    </div>
  );
};

Controls.propTypes = {
  children: PropTypes.node,
};

export default Controls;
