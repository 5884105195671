import React from "react";

const DeezerWidget = ({ content }) => {

  /*
  * Using the Deezer Widget-Functionality to embed an iframe.
  * Users only need to insert the Artists Page. We will strip the ID out of the url and insert it into the given Iframe Boilerplate.
  * Functionality Infos can be found here: https://widget.deezer.com/?type=artist&id=52512372
  * Currently (15.05.24) The Artist URL is structured in the following way: https://www.deezer.com/de/artist/52512372
  */

  if (content.fieldDeezer && content.fieldDeezer.uri) {

    // Split divides the url into all segments divided by Slash, then the length of this array is used to get the last element of it which is the artist ID.
    const deezerArtistID = content.fieldDeezer.uri.path.split("/")[content.fieldDeezer.uri.path.split("/").length - 1];

    return (
      <div className="deezer-widget">
        <iframe
          title="deezer-widget"
          src={`https://widget.deezer.com/widget/dark/artist/${deezerArtistID}/top_tracks?tracklist=false`}
          width="100%"
          height="300"
          frameBorder="0"
          allowTransparency="true"
          allow="encrypted-media;clipboard-write"
        />
      </div>
    )
  }
};

export default DeezerWidget;
