import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

const ParagraphContact = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-contact": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="row">
              {content.fieldReferencedContent.map((item, index) => (
                <div
                  key={index}
                  className="col-16 col-lg-8"
                >
                  <ErrorBoundary>
                    <TeaserBase
                      showImage={true}
                      item={item}
                      viewMode={"contact"}
                    />
                  </ErrorBoundary>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphContact.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldShowImages: PropTypes.bool,
    fieldReferencedContent: PropTypes.arrayOf(teaserPersonPropTypes),
  }),
};

export default ParagraphContact;
