import React, { useRef } from "react";

import PropTypes from "prop-types";
//import {useSelector} from "react-redux";
import ScrollOverview from "../../scroll-overview/scroll-overview";
import useOverviewContext from "../store/use-overview-context";

const OverviewActs = () => {
  const { nodes } = useOverviewContext();

  //const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  return (
    <div className="acts-overview">
      <ScrollOverview
        type={"participant"}
        participantTypeFilter={"act"}
        nodes={nodes}
        tags={false}
        showBanners={false}
        updateUrl={false}
        displayType={"list"}
        clickBehavior={"link"}
      />
    </div>
  );
};

export default OverviewActs;
