import React from "react";
import PropTypes from "prop-types";

import SimpleNavigation from "./simple-navigation";

const Header = (props) => {
  return (
    <header id="site-header">
      <div className={"fixed-wrapper"}>
        <SimpleNavigation
          showSearch={props.showSearch}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
