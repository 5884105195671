import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import TopicClickSuggestion from "./click-suggestion-topic";
import LoadingIndicator from "@general-components/loading-indicator";
import Link from "@general-components/link/link";
import {useLocation} from "react-router-dom";

const ClickSuggestionsTopicsRbxCompany = ({ content }) => {
  const [swiperComponents, setSwiperComponents] = useState({ Swiper: null, SwiperSlide: null });
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const loadSwiper = async () => {
      try {
        const { Swiper, SwiperSlide } = await import('swiper/react');
        await import('swiper/css');

        setSwiperComponents({ Swiper, SwiperSlide });
        setIsCssLoaded(true);
      } catch (err) {
        console.error("Error importing Swiper, modules or CSS:", err);
      }
    };

    loadSwiper();
  }, []);

  const { Swiper, SwiperSlide } = swiperComponents;

  if (!Swiper || !SwiperSlide || !isCssLoaded) {
    return <LoadingIndicator fullPage={true}/>;
  }

  return (
    <div
      className={""}
    >
      <Swiper
        spaceBetween={16}
        slidesPerView={3}
        breakpoints={{
          0: {
            slidesPerView: 1.1,
          },
          768: {
            slidesPerView: 2.1,
          },
          1024: {
            slidesPerView: 2.8
          }
        }}
      >
        {content.fieldTopicsClickSuggestions && content.fieldTopicsClickSuggestions.map(
          (link, index) => (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <li key={index}>
                  <Link
                    link={link.fieldLink}
                    forceSameTab={link.fieldLink.uri.path.includes(location.pathname)}
                  >
                    <TopicClickSuggestion content={link} />
                  </Link>
                </li>
              )}
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  );
};

ClickSuggestionsTopicsRbxCompany.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldTopicsClickSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        fieldImage: PropTypes.object,
        fieldLink: PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.shape({
            routed: PropTypes.bool,
            path: PropTypes.string,
          }),
        })
      })
    ),
  }),
};

export default ClickSuggestionsTopicsRbxCompany;