import React from "react";
import { useSelector } from "react-redux";
import FieldTime from "@general-components/date-time/field-time";
import DateWrapper from "@js/participants/components/date-wrapper";

const AppearanceDetailed = ({
  appearance,
  type,
  showAddToCalendar,
  viewMode,
  forceLongDayName,
  showEndDate
}) => {
  const releaseState = useSelector(
    (reduxStore) => reduxStore.appStore.releaseState
  );

  return (
    <article className="appearance simple">
      <div className="row">
        <div className="time">
          <div className="day body-xs make-headline-light">
            {releaseState.showDay && (
              <FieldTime
                timestamp={true}
                date={appearance.fieldDateRawField.first.value}
                format={forceLongDayName ? "dddd" : "dd"}
                dependsOnReleaseState={true}
              />
            )}
          </div>
          <div className="time body-xs">
            <DateWrapper
              content={appearance}
              viewMode={"time"}
              showEndDate={showEndDate}/>
          </div>
        </div>
        {appearance.fieldVenueLocation &&
          <div className="venue d-flex flex-row icon-flex-wrap make-headline">
            <span
              className="venue-name body-xs make-headline"
            >
              {appearance.fieldVenueLocation.label}
            </span>
          </div>
        }
      </div>
    </article>
  );
};

export default AppearanceDetailed;
