import React from "react";
import Image from "../../../image/image";

const DownloadGallery = ({ content }) => {

  return (
    <div className={"download-gallery"}>
      {/* Single out first element */}
      {content[0] &&
        <div className="gallery-element single">
          <Image data={content[0].entity.fieldMediaImage} />
        </div>
      }
      {content.length > 1 && content.map((thumbnailImage, thumbnailImageIndex) => {
        {/* Hide first element, show other hover images */}
        if (thumbnailImageIndex !== 0) {
          return(
            <div className="gallery-element hover" key={thumbnailImageIndex}>
              <Image data={thumbnailImage.entity.fieldMediaImage} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default DownloadGallery;
