import React from "react";
import PropTypes from "prop-types";
import iconLinksBlack from "../../../assets/ui/icon-links-black.svg";

const SocialLinks = ({content}) => {
  if (
    content.fieldYoutube ||
    content.fieldFacebook ||
    content.fieldInstagram ||
    content.fieldTwitter ||
    content.fieldSoundcloud ||
    content.fieldWebsite ||
    content.fieldVimeo
  ) {
    return (
      <div className="social-wrapper">
        {(content.fieldYoutube ||
          content.fieldFacebook ||
          content.fieldInstagram ||
          content.fieldTwitter ||
          content.fieldSoundcloud ||
          content.fieldWebsite ||
          content.fieldVimeo) && (
          <div className="social-links">
            <div className="icon-wrapper">
              <img
                src={iconLinksBlack}
                className="icon links"
                alt="Links"
              />
            </div>
            <div className="link-wrapper">
              {content.fieldWebsite && content.fieldWebsite.uri && (
                <a
                  className={"link website"}
                  href={content.fieldWebsite.uri.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {content.fieldWebsite.uri.path
                    .replace("https://", "")
                    .replace("http://", "")}
                </a>
              )}
              {content.fieldFacebook && content.fieldFacebook.uri && (
                <a
                  className={"link facebook"}
                  href={content.fieldFacebook.uri.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Facebook
                </a>
              )}
              {content.fieldInstagram &&
                content.fieldInstagram.uri && (
                  <a
                    className={"link facebook"}
                    href={content.fieldInstagram.uri.path}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                )}
              {content.fieldTwitter && content.fieldTwitter.uri && (
                <a
                  className={"link twitter"}
                  href={content.fieldTwitter.uri.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Twitter
                </a>
              )}
              {content.fieldSoundcloud &&
                content.fieldSoundcloud.uri && (
                  <a
                    className={"link soundcloud"}
                    href={content.fieldSoundcloud.uri.path}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Soundcloud
                  </a>
                )}
              {content.fieldYoutube && content.fieldYoutube.uri && (
                <a
                  className={"link youtube"}
                  href={content.fieldYoutube.uri.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  YouTube
                </a>
              )}
              {content.fieldVimeo && content.fieldVimeo.uri && (
                <a
                  className={"link vimeo"}
                  href={content.fieldVimeo.uri.path}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Vimeo
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return false;
  }
};

SocialLinks.propTypes = {
  content: PropTypes.object.isRequired,
};

export default SocialLinks;
