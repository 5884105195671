import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";

/**
 * Use this to render links from Drupal - internal and external links are
 * differentiated based on the __typename of the URL.
 */
const Link = (props) => (
  <>
    {props.link?.uri?.path?.includes("/node/") ? null : (
      <>
        {props?.link?.options?.href?.includes("/node/") || props.forceSameTab ? (
          <ReactRouterLink
            className={props.className}
            to={props.link.uri.path}
            draggable="false"
          >
            {props.children ? (
              <>{props.children}</>
            ) : (
              <>{props.link.title}</>
            )}
          </ReactRouterLink>
        ) : (
          <a
            className={props.className}
            target="_blank"
            rel="noopener noreferrer"
            href={props.link.uri.path}
            draggable="false"
          >
            {props.children ? (
              <>{props.children}</>
            ) : (
              <>{props.link.title}</>
            )}
          </a>
        )}
      </>
    )}
  </>
);

export const LinkPropType = PropTypes.shape({
  uri: PropTypes.shape({
    __typename: PropTypes.string,
    path: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
});

Link.propTypes = {
  className: PropTypes.string,
  link: LinkPropType.isRequired,
  forceSameTab: PropTypes.bool
};

export default Link;
