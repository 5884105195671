import React, { useEffect, Suspense } from "react";
import memoize from "memoize-one";

// Router
import { Route, Routes, Switch, useLocation } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./../../i18n/translations/de.json";
import messages_en from "./../../i18n/translations/en.json";

// Page Components
import RBXPage from "./nodes/rbx-page/rbx-page";
import PageFooter from "./../../footer/page-footer";
import RBXHeader from "./../../microsites/rbx-company/rbx-header";
import ErrorBoundary from "./../../error-boundary";
import { AnimatePresence } from "framer-motion";
import Error404 from "@system/error-404";

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  de: messages_de,
  en: messages_en,
  und: messages_de,
};

/**
 * @todo 404.
 */
const App = () => {
  const location = useLocation();
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  useEffect(() => {
    document.body.className += `${generateMainClasses(location.pathname)}`;
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
      document.body.className = `${generateMainClasses(location.pathname)}`;
    }
  }, [location.pathname]);

  return (
    <IntlProvider
      locale={currentLanguage !== "und" ? currentLanguage : defaultLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLanguage]}
    >
      <div className="main-content">
        <ErrorBoundary>
          <RBXHeader />
        </ErrorBoundary>

        <main className="main-page-content">
          <ErrorBoundary>
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route exact path="/:language?/:alias" element={<RBXPage />} />
                <Route
                  exact
                  path="/:language?/:alias#brand-:alias"
                  element={<RBXPage />}
                />
                <Route exact path="/:language?" element={<RBXPage />} />
                <Route exact path="/" element={<RBXPage />} />
                <Route path="*" element={<Error404 />} status={404} />
              </Routes>
            </AnimatePresence>
          </ErrorBoundary>
        </main>

        <ErrorBoundary>
          <PageFooter />
        </ErrorBoundary>
      </div>
    </IntlProvider>
  );
};

App.propTypes = {};

export default App;
