import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";

import BusinessWrapper from "../../../participants/components/business-wrapper";
import FeaturedEvents from "@js/participants/components/featured-events";

import CustomHyphenation from "@general-components/custom-hyphenation";

const TeaserSpeaker = ({ item, index, updateUrl }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const isMobile = useSelector((reduxStore) => reduxStore.appStore.isMobile);

  const teaser = useRef();
  const teaserImage = useRef();

  const location = useLocation();

  return (
    <article
      className={`node node-teaser teaser-speaker programme-element ${item.fieldImage ? "with-image" : "no-image"} col-16`}
      ref={teaser}
      data-entity-id={item.id}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link to={item.url.path} className="container">
        <div className="row">
          <div
            className="col-8 col-md-3 image"
            ref={teaserImage}
          >
            {item.fieldImage?.fieldMediaImage && (
              <Image
                data={
                  item.fieldImage.fieldMediaImage
                }
                nodeTitle={item.title}
                styleSmall={true}
              />
            )}
          </div>
          {!isMobile &&
            <div className="col-8 offset-8 offset-md-0 col-md-4 col-xl-3 featured-events">
              <FeaturedEvents content={item}/>
            </div>
          }
          <div className="col-8 col-md-9 col-xl-10 main-infos">
            <div
              className="title-wrapper headline-s d-flex"
            >
              <h2 className="name headline-m">
                <CustomHyphenation length={8}>
                  {item.title}
                </CustomHyphenation>
              </h2>
              {!isMobile && item.fieldCountry && item.fieldCountry?.value && (
                <div className="tag body-xxs">
                  {item.fieldCountry.value}
                </div>
              )}
            </div>
            <BusinessWrapper content={item}/>
            {isMobile && item.fieldCountry && item.fieldCountry?.value && (
              <div className="tag body-xxs">
                {item.fieldCountry.value}
              </div>
            )}
          </div>
        </div>
      </Link>
    </article>
  );
};

export const teaserSpeakerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  updateUrl: PropTypes.bool,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserSpeaker.propTypes = {
  item: teaserSpeakerPropTypes,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default TeaserSpeaker;