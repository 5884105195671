import React from "react";
import NodeWrapper from "@nodes/shared-components/node-wrapper";
import useNodeQuery from "@hooks/use-node-query";
import rbxPageQuery from "./rbx-page.graphql";
import ContentBase from "@general-components/content-base/content-base";
import {useLocation} from "react-router-dom";
import Brand from "@nodes/brand/brand";

const RBXPage = () => {

  const { entity, loading, error } = useNodeQuery(rbxPageQuery),
    location = useLocation();

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      {entity?.fieldModules && (
        <ContentBase
          content={entity.fieldModules.list}
          viewMode={"standard"}
        />
      )}

      {location.hash.includes("brand") &&
        <Brand location={location}/>
      }
    </NodeWrapper>
  );
};

RBXPage.propTypes = {};

export default RBXPage;
