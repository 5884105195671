import React from "react";
import ErrorBoundary from "../../../../../error-boundary";
import PropTypes from "prop-types";
import VideoEmbedded from "./video-embedded";
import VideoFile from "./video-file";

const Video = ({ content, autoplay, customThumbnail, forcePlay, loop }) => {
  return (
    <div className="video-wrapper">
      {(() => {
        switch (content?.entityBundle) {
          case "embedded_video":
            return (
              <ErrorBoundary>
                <VideoEmbedded
                  content={content}
                  autoplay={autoplay}
                  customThumbnail={customThumbnail}
                  forcePlay={forcePlay}
                />
              </ErrorBoundary>
            );
          case "video":
            return (
              <ErrorBoundary>
                <VideoFile
                  content={content}
                  autoplay={autoplay}
                  customThumbnail={customThumbnail}
                  forcePlay={forcePlay}
                  loop={loop}
                />
              </ErrorBoundary>
            );
          default:
            return false;
        }
      })()}
    </div>
  );
};

Video.propTypes = {
  content: PropTypes.object.isRequired,
  customThumbnail: PropTypes.object,
  autoplay: PropTypes.bool,
};

export default Video;
