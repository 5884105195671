import React, { useRef } from "react";

import ErrorBoundary from "../../../../../error-boundary";

import TeaserVenue from "../../../../teaser-base/venue/teaser-venue";
import TeaserVenueImage from "../../../../teaser-base/venue/teaser-venue-image";
import Scrollspy from "react-scrollspy";
import { encodeUrl } from "../../../../../lib/encode-url";
import { changeActiveImage } from "../../../../../lib/change-active-image";
import { useSelector } from "react-redux";
import useOverviewContext from "../store/use-overview-context";
import CustomAnimation from "@general-components/custom-animation";
import Controls from "../components/main-controls";
import FilterControls from "../components/main-controls/filter-controls";
import {FormattedMessage} from "react-intl";

const OverviewVenues = () => {
  const { nodes } = useOverviewContext();
  const locationsOverview = useRef();

  const isMobile = useSelector((state) => state.appStore.isMobile);

  return (
    <div className="venues-overview" ref={locationsOverview}>
      <Controls>
        <FilterControls>
          <FormattedMessage id="locations.filter"/>
        </FilterControls>
      </Controls>
      {!!nodes?.length && (
        <div className="container">
          {isMobile ? (
            <div className={"row"}>
              <CustomAnimation type={"appear"} isVisible={true}>
                {nodes.map((item, index) => (
                  <React.Fragment key={index}>
                    <ErrorBoundary>
                      <TeaserVenue
                        item={item}
                        index={index}
                        updateUrl={true}
                        pagerFullPage={false}
                      />
                    </ErrorBoundary>
                  </React.Fragment>
                ))}
              </CustomAnimation>
            </div>
          ) : (
            <div className="row">
              <div className={"col-8 image-wrapper"}>
                <div className={"movement-wrapper"}>
                  <Scrollspy
                    offset={-350}
                    items={nodes.map((item) => encodeUrl(item.label))}
                    componentTag={"div"}
                    currentClassName={"is-current"}
                    onUpdate={(el) => {
                      changeActiveImage(el, locationsOverview.current);
                    }}
                  >
                    {nodes.map((item, index) => (
                      <a
                        href={`#${encodeUrl(item.label)}`}
                        aria-label={item.label}
                        key={index}
                      >
                        <ErrorBoundary>
                          <span className={"sr-only"}>{item.label}</span>
                          <TeaserVenueImage
                            item={item}
                            index={index}
                            updateUrl={true}
                            pagerFullPage={false}
                          />
                        </ErrorBoundary>
                      </a>
                    ))}
                  </Scrollspy>
                </div>
              </div>
              <div className={"col-8 teaser-wrapper"}>
                <CustomAnimation type={"appear"} isVisible={true}>
                  {nodes.map((item, index) => (
                    <React.Fragment key={index}>
                      <ErrorBoundary>
                        <TeaserVenue
                          item={item}
                          index={index}
                          updateUrl={true}
                          pagerFullPage={false}
                        />
                      </ErrorBoundary>
                    </React.Fragment>
                  ))}
                </CustomAnimation>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OverviewVenues;
