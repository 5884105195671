import React from "react";
import useOverviewContext from "@paragraphs/overview/store/use-overview-context";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

/**
 * Component for the day controls.
 * This component will display buttons for each day of the week.
 * @returns {JSX.Element} The DayControls component.
 */
const DayControls = () => {
  const { searchParams, setSearchParams } = useOverviewContext();

  const buttonClickHandler = (day) => {
    setSearchParams((_searchParams) => {
      // reset the filter if the same button is clicked again
      if (searchParams.get("weekday") === day) {
        _searchParams.delete("weekday");
      } else {
        _searchParams.set("weekday", day);
      }

      // return the new values
      return _searchParams;
    });
  };

  const weekdays = ["wed", "thu", "fri", "sat"];

  const currentIndex = weekdays.indexOf(searchParams.get("weekday"));

  return (
    <div
      className="main-control group-switch filter-control-element day-controls"
      style={{
        "--animation-children": weekdays.length,
        "--current-index": currentIndex < 0 ? undefined : currentIndex,
        "--opacity": currentIndex < 0 ? 0 : 1,
      }}
    >
      <small className="d-block label sr-only">Sort By</small>
      {weekdays.map((day) => (
        <button
          key={day}
          className={classNames({
            "font-style-change": true,
            active: searchParams.get("weekday") === day,
          })}
          onClick={() => buttonClickHandler(day)}
        >
          <span className="highlighted">
            <FormattedMessage id={`weekday.short.${day}`} />
          </span>
          <span className="standard">
            <FormattedMessage id={`weekday.short.${day}`} />
          </span>
        </button>
      ))}
    </div>
  );
};

DayControls.propTypes = {};

export default DayControls;
