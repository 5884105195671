import { useEffect } from "react";
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import PropTypes from "prop-types";

const useLockBodyScroll = ({ element }) => {
  // Can get a scope, standard is document.
  const targetElement = element || document.body;

  // ScrollLock Options: https://www.npmjs.com/package/body-scroll-lock#options
  const options = {
    reserveScrollBarGap: true,
  };

  useEffect(() => {
    disableBodyScroll(targetElement, options);

    return () => {
      clearAllBodyScrollLocks(targetElement, options);
    };
  });
};

export const LockBodyScroll = () => {
  useLockBodyScroll();
  return null;
};

useLockBodyScroll.propTypes = {
  element: PropTypes.Object,
};

export default useLockBodyScroll;