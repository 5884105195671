import React from "react";
import {FormattedMessage} from "react-intl";
import TeaserActShowcase from "@teasers/act/teaser-act-showcase";

const ShowcaseActs = ({ content }) => {

  let showcaseArtistsFromAppearances = [];
  content?.fieldAppearances?.map((act) => { showcaseArtistsFromAppearances.push(...act?.fieldShowcaseActs) });

  showcaseArtistsFromAppearances = showcaseArtistsFromAppearances?.map((appearance) => { return appearance });

  let showcaseArtists = [...content?.fieldShowcaseArtists, ...showcaseArtistsFromAppearances];

  if (showcaseArtists?.length >= 1) {
    return(
      <div className="showcase-acts">
        <div className="container">
          <div className="row">
            <div className="col-16 label headline-m">
              <FormattedMessage id={"acts"}/>
            </div>
            {showcaseArtists.map((act, actId) => (
              <React.Fragment key={actId}>
                <TeaserActShowcase
                  item={act}
                  pagerFullPage={false}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ShowcaseActs;
