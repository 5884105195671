import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import ContentBase from "../../content-base";

import { encodeUrl } from "@lib/encode-url";
import {calculateCollapseHeight} from "@lib/calculate-collape-height";
import {useSelector} from "react-redux";

const ParagraphCollapse = ({ content }) => {
  const collapse = useRef(),
    microSite = useSelector((reduxStore) => reduxStore.appStore.microSite);

  const sectionClassNames = classNames({
    "paragraph paragraph-collapse": true,
    "with-borders": true,
  }),
  colClassNames = classNames({
    "col-16": true,
    "col-md-14 offset-md-1": microSite === "rbx-company",
  });

  let preExpandedItem = null;


  content.fieldCollapseElements.map((item, index) => {
    if ((typeof window !== "undefined") && item && encodeUrl(item.fieldHeading) === window.location.hash.replace('#', '')) {
      preExpandedItem = item.id;
    }
  });

  const updateAccordionHash = (uuids) => {
    // Check if there are opens elements left, first element defaults to null
    if (uuids[uuids.length - 1] !== null) {
      // Get the Corresponding Element by UUID to get its Title
      content.fieldCollapseElements.map((item, index) => {
        // Check if UUID matches paragraphs entity id, if yes, push the title to the url
        if (item.id === uuids[uuids.length - 1]) {
          // Set the URL Hash
          history.pushState(
            null,
            window.location.pathname,
            `#${encodeUrl(item.fieldHeading)}`
          );
        }
      });
    }
  }

  useEffect(() => {
    calculateCollapseHeight(collapse.current);

    if (preExpandedItem) {
      // Scroll to Element
      if (typeof window !== undefined && window.location.hash) {
        // get Element by extracting id from hash, find matching id in element
        let openedElement = document.getElementById(window.location.hash.replace("#", ""));

        if (openedElement) {
          let scrollPosition = openedElement.getBoundingClientRect().top + window.scrollY - 150;
          setTimeout(() => {
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth"
            });
          }, 2500);
        }
      }
    }
  }, [])


  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
      ref={collapse}
    >
      <div className="container">
        <div className="row">
          <div className={colClassNames}>
            <React.Suspense fallback={<div />}>
              <Accordion
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                preExpanded={[preExpandedItem]}
                onChange={(uuids) => updateAccordionHash(uuids)}
              >
                {content.fieldCollapseElements.map((item, index) => (
                  <ErrorBoundary key={index}>
                    <AccordionItem
                      id={encodeUrl(item.fieldHeading).toString()}
                      uuid={item.id}
                      key={encodeUrl(item.fieldHeading)}
                      data-uuid={item.id}
                    >
                      <div className="dashed-border"/>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <div className="heading">
                            {item.fieldHeading}
                          </div>
                          <div className="toggle-indicator toggle">
                            <div className="icon plus"/>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {item.fieldCollapseContent &&
                          <div className="collapse-content">
                            <ContentBase
                              content={item.fieldCollapseContent}
                              nodeContent={false}
                              viewMode={"collapse"}
                            />
                          </div>
                        }
                      </AccordionItemPanel>
                    </AccordionItem>
                  </ErrorBoundary>
                ))}
              </Accordion>
            </React.Suspense>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCollapse.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldCollapseElements: PropTypes.arrayOf(
      PropTypes.shape({
        fieldHeading: PropTypes.string,
        fieldCollapseContent: PropTypes.array,
      })
    ),
  }),
};

export default ParagraphCollapse;
