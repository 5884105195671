const changeActiveImage = (element, ref) => {
  // Fires only if props are given
  if (element && element.parentElement) {
    // Get all needed content Teasers and search for currently active image
    let allTeasers = element.parentElement.querySelectorAll('.scroll-overview-listener, .venue-element');
    let activeId = [...allTeasers].indexOf(element);

    // Remove active listener from all elements, add it to the active one
    if (element.parentElement && allTeasers) {
      allTeasers.forEach((item) => {
        item.classList.remove('active-scroll-spy');
      });
      element.classList.add("active-scroll-spy");
    }

    // Set offset if active element is first element
    let offset = window.innerHeight * 0.1;

    // offset for first element
    if (activeId === 0) {
      offset = 0;
    }
    if (activeId === (allTeasers.length - 1)) {
      offset = window.innerHeight * 0.3;
    }

    // Set "Spin movement" based on active image index. window.innerHeight needs to be the same size as the image in css.
    ref.style.setProperty("--active-image-transition", `${offset + (window.innerHeight * 0.8) * activeId * -1}px`);
  }
};

export { changeActiveImage };
