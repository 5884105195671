import React, { Component } from "react";
import PropTypes from "prop-types";
import ChatBot from "react-simple-chatbot";
import ChooseAStyle from "./choose-a-style";
import Video from "./video";
import Links from "./links";
import Audio from "./audio";
import Image from "./image";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { compose } from "recompose";
import eforkBotConfigQuery from "./eforkbot-config-query.graphql";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  nextStep: reduxStore.appStore.nextStep,
});

class Eforkbot extends Component {
  state = {
    steps: null,
  };

  componentDidMount() {
    let steps = [];
    if (
      this.props.eforkBotConfig &&
      typeof this.props.eforkBotConfig.configPagesById !== "undefined"
    ) {
      this.props.eforkBotConfig.configPagesById.fieldChatbotElements.map(
        (ChatBotStep) =>
          (() => {
            switch (ChatBotStep.entity.__typename) {
              case "ParagraphChatbotMessage":
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  message: ChatBotStep.entity.fieldText.value.replace(
                    /<[^>]*>?/gm,
                    ""
                  ),
                  trigger: ChatBotStep.entity.fieldTrigger,
                });
                break;
              case "ParagraphChatbotEnd":
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  message: ChatBotStep.entity.fieldText.value.replace(
                    /<[^>]*>?/gm,
                    ""
                  ),
                  end: true,
                });
                break;
              case "ParagraphChatbotOptions": {
                const options = [];

                ChatBotStep.entity.fieldOptions.map((option) => {
                  options.push({
                    value: option.entity.fieldLabel,
                    label: option.entity.fieldLabel,
                    trigger: option.entity.fieldTrigger,
                  });
                });
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  options,
                });
                break;
              }

              case "ParagraphChatbotChooseAStyle": {
                const styles = [];

                ChatBotStep.entity.fieldStyles.map((style) => {
                  styles.push({
                    value: style.entity.fieldLabel,
                    customTrigger: style.entity.fieldTrigger,
                  });
                });
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  component: <ChooseAStyle value={styles} />,
                });
                break;
              }
              case "ParagraphChatbotLinks": {
                const links = [];

                ChatBotStep.entity.fieldChatbotLinks.map((link) => {
                  links.push({
                    url: link.entity.fieldTeaserbild,
                    label: link.entity.fieldLabel,
                    text: link.entity.fieldText,
                    link: link.entity.fieldLink,
                    customTrigger: link.entity.fieldTrigger,
                  });
                });
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  component: <Links value={links} />,
                  trigger: ChatBotStep.entity.fieldTrigger,
                });
                break;
              }
              case "ParagraphChatbotImage":
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  component: (
                    <Image
                      text={ChatBotStep.entity.fieldText}
                      url={
                        ChatBotStep.entity.fieldBilderWCaption[0].entity
                          .fieldMediaImage.style.url
                      }
                      customTrigger={ChatBotStep.entity.fieldTrigger}
                    />
                  ),
                  trigger: ChatBotStep.entity.fieldTrigger,
                });
                break;
              case "ParagraphChatbotAudio":
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  component: <Audio url={ChatBotStep.entity.fieldAudio} />,
                  trigger: ChatBotStep.entity.fieldTrigger,
                });
                break;
              case "ParagraphChatbotVideo":
                steps.push({
                  id: ChatBotStep.entity.fieldChatbotId,
                  component: (
                    <Video
                      text={ChatBotStep.entity.fieldText}
                      url={
                        ChatBotStep.entity.fieldVideo.entity.fieldMediaVideoFile
                          .entity.url
                      }
                      customTrigger={ChatBotStep.entity.fieldTrigger}
                    />
                  ),
                  trigger: ChatBotStep.entity.fieldTrigger,
                });
                break;
              default:
                return null;
            }
          })()
      );
      this.setState({
        steps,
      });
    }
  }

  render() {
    return (
      <section className="eforkbot">
        <div className="container">
          <div className="row">
            <div className="col-16">
              {this.state.steps && (
                <ChatBot
                  steps={this.state.steps}
                  botDelay={0}
                  userDelay={500}
                  customDelay={0}
                  width={"100%"}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Eforkbot.propTypes = {
  content: PropTypes.object.isRequired,
  eforkBotConfig: PropTypes.object
};

export default compose(
  graphql(eforkBotConfigQuery, {
    name: "eforkBotConfig",
  })
)(connect(mapStateToProps)(Eforkbot));
