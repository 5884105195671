import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";

import teaserNodeQueryEventTagFiltered from "../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import TeaserNews from "../../../teaser-base/news/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserProject from "../../../teaser-base/project/teaser-project";
import { pagerFullPagesAction } from "../../../../app-actions";
import ComponentExtendedTeaserOverviewSearch from "./components/component-extended-teaser-overview-search";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser-base/event/teaser-event";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ParagraphExtendedTeaserOverview extends Component {
  static defaultProps = {
    skipQuery: false,
    filterCloudLabels: [],
    filterCloudFilters: ["fieldSchlagwort"],
    filterCloudFilterSingle: true,
    filterCloudMultipleFilterCondition: "OR",
    filterCloudPreventEmptyResult: true,
  };

  itemSection = React.createRef();

  filterItems = (filter = [], search = "") => {
    let filteredItems = [];

    const activeFilter =
        filter.length > 0
          ? filter
          : this.state
          ? this.state.activeFilter
          : null,
      activeSearch =
        search !== "" ? search : this.state ? this.state.activeSearch : null;

    if (
      (activeFilter && activeFilter.length > 0) ||
      (activeSearch && activeSearch !== "")
    ) {
      if (
        activeFilter.length === 1 &&
        activeFilter[0].id &&
        !activeFilter[0].field
      ) {
        // Default (old) filtering with fieldSchlagwort.
        filteredItems = this.props.nodes.entityQuery.items.filter((item) =>
          item.fieldSchlagwort.some((tag) =>
            tag ? tag.id === activeFilter.id : false
          )
        );
      } else if (activeFilter.length > 0) {
        // Filter all selected filters.
        filteredItems = this.props.nodes.entityQuery.items.filter((item) => {
          if (this.props.filterCloudMultipleFilterCondition === "OR") {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                continue;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (item[activeFilterItem.field] === activeFilterItem.id) {
                  return true;
                }

                continue;
              }

              // Single Entity reference fields.
              if (
                item[activeFilterItem.field] &&
                item[activeFilterItem.field].id
              ) {
                if (item[activeFilterItem.field].id === activeFilterItem.id) {
                  return true;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                item[activeFilterItem.field].some(
                  (tag) => tag && tag.id === activeFilterItem.id
                )
              ) {
                return true;
              }
            }
          } else {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                return false;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (item[activeFilterItem.field] !== activeFilterItem.id) {
                  return false;
                }

                continue;
              }

              // Single Entity reference fields.
              if (
                item[activeFilterItem.field] &&
                item[activeFilterItem.field].id
              ) {
                if (item[activeFilterItem.field].id !== activeFilterItem.id) {
                  return false;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                !item[activeFilterItem.field].some(
                  (tag) => tag && tag.id === activeFilterItem.id
                )
              ) {
                return false;
              }
            }

            return true;
          }
        });
      } else {
        // No active filter.
        filteredItems = this.props.nodes.entityQuery.items;
      }

      // Search.
      if (activeSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          const parsedItem = JSON.stringify(item).toLowerCase();

          return parsedItem.includes(activeSearch);
        });
      }
    } else {
      filteredItems = this.props.nodes.entityQuery.items;
    }

    return filteredItems;
  };

  getItemsToShow = (start, end) => {
    if (
      !this.props.nodes.loading &&
      this.props.nodes.entityQuery.items.length >= 1
    ) {
      if (!end) {
        end = this.props.nodes.entityQuery.items.length;
      }

      const items = this.filterItems();

      if (this.props.content.fieldPagerVerwenden) {
        return items.slice(start, end);
      }

      return items;
    }

    return [];
  };

  state = {
    itemsMatching: this.getItemsToShow(0),
    itemsToShow: this.getItemsToShow(
      0,
      this.props.content.fieldPagerVerwenden
        ? this.props.content.fieldElementeProSeite
        : null
    ),
    activeFilter: [],
    activeSearch: "",
  };

  pushPagerFullPageConfig = () => {
    if (
      this.props.content.fieldPagerAufVollseiten &&
      this.props.nodes.entityQuery
    ) {
      const pagerFullPagesConfig = {
        id: this.props.content.id,
        items: this.props.nodes.entityQuery.items,
        overviewLink: this.props.content.fieldLinkZurUebersicht,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.entityQuery) !==
      JSON.stringify(this.props.nodes.entityQuery)
    ) {
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.content.fieldPagerVerwenden
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      });
      this.pushPagerFullPageConfig();
    }
  }

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.filter(
        (item) =>
          item.id === activeFilter.id && item.field === activeFilter.field
      ).length > 0
    ) {
      this.setState({ activeFilter: null }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState(
        {
          activeFilter: {
            ...this.state.activeFilter,
            activeFilter,
          },
        },
        () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.content.fieldPagerVerwenden
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
      );
    }
  };

  changeActiveFilterCloud = (activeFilter) => {
    // First check "only one filter at a time" option.
    if (this.props.filterCloudFilterSingle) {
      if (
        this.state.activeFilter.length > 0 &&
        this.state.activeFilter[0].id === activeFilter.id
      ) {
        this.setState({ activeFilter: [] }, () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.content.fieldPagerVerwenden
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
        );
      } else {
        this.setState(
          {
            activeFilter: [activeFilter],
          },
          () =>
            this.setState({
              itemsMatching: this.getItemsToShow(0),
              itemsToShow: this.getItemsToShow(
                0,
                this.props.content.fieldPagerVerwenden
                  ? this.props.content.fieldElementeProSeite
                  : null
              ),
            })
        );
      }

      return;
    }

    if (
      this.state.activeFilter &&
      this.state.activeFilter.filter(
        (item) =>
          item.id === activeFilter.id && item.field === activeFilter.field
      ).length > 0
    ) {
      // Remove filter from active filters.
      const newActiveFilter = this.state.activeFilter.filter(
        (item) =>
          item.id !== activeFilter.id || item.field !== activeFilter.field
      );
      this.setState({ activeFilter: newActiveFilter }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      // Add filter to active filters.
      this.setState(
        { activeFilter: [...this.state.activeFilter, activeFilter] },
        () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.content.fieldPagerVerwenden
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
      );
    }
  };

  changeActiveSearch = (searchValue) => {
    if (typeof searchValue === "undefined") {
      this.setState({ activeSearch: "" }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeSearch: searchValue.toLowerCase() }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.content.fieldPagerVerwenden
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  handlePageClick = (data) => {
    this.setState(
      {
        itemsToShow: this.getItemsToShow(
          data.selected * this.props.content.fieldElementeProSeite,
          data.selected * this.props.content.fieldElementeProSeite +
            this.props.content.fieldElementeProSeite
        ),
      },
      () => scroll.scrollTo(this.itemSection.current.offsetTop)
    );
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-extended-teaser-overview": true,
      [`type-${this.props.content.fieldTypExtended}`]: true,
    });

    return (
      <section className={sectionClassNames} ref={this.itemSection}>
        {!this.props.nodes.loading &&
          this.props.nodes.entityQuery.items.length > 1 &&
          this.props.content.fieldFilterwolke && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={this.props.nodes.entityQuery.items}
                filter={this.props.filterCloudFilters}
                labels={this.props.filterCloudLabels}
                activeFilter={this.state.activeFilter}
                filterItems={this.filterItems}
                preventEmptyResult={this.props.filterCloudPreventEmptyResult}
                changeActiveFilter={this.changeActiveFilterCloud}
              />
            </ErrorBoundary>
          )}

        {!this.props.nodes.loading &&
          this.props.nodes.entityQuery.items.length > 1 &&
          this.props.content.fieldSucheAktivieren && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewSearch
                changeActiveSearch={this.changeActiveSearch}
              />
            </ErrorBoundary>
          )}

        <div className="container">
          <div className={`row ${this.props.content.fieldDarstellung}`}>
            {this.state.itemsToShow.length >= 1 &&
              this.state.itemsToShow.map((item, index) => (
                <React.Fragment key={index}>
                  {(() => {
                    switch (item.entityBundle) {
                      case "news":
                        return (
                          <div className="col-16 col-md-4 offset-md-1">
                            <ErrorBoundary>
                              <TeaserNews
                                item={item}
                                pagerFullPage={
                                  this.props.content.fieldPagerAufVollseiten
                                    ? this.props.content.id
                                    : false
                                }
                              />
                            </ErrorBoundary>
                          </div>
                        );
                      case "person":
                        return (
                          <div className="col-16 col-md-4 offset-md-1">
                            <ErrorBoundary>
                              <TeaserPersonOverview
                                item={item}
                                pagerFullPage={
                                  this.props.content.fieldPagerAufVollseiten
                                    ? this.props.content.id
                                    : false
                                }
                              />
                            </ErrorBoundary>
                          </div>
                        );
                      case "projekt":
                        return (
                          <div
                            className={classNames({
                              "col-16 col-md-custom":
                                (index === 0 || (index + 1) % 7 !== 0) &&
                                this.props.content.fieldDarstellung ===
                                  "small_highlighted",
                              "col-16 col-md-custom-highlighted":
                                index !== 0 &&
                                (index + 1) % 7 === 0 &&
                                this.props.content.fieldDarstellung ===
                                  "small_highlighted",
                              "col-md-5":
                                this.props.content.fieldDarstellung ===
                                  "small_big" &&
                                (index === 0 ||
                                  (index % 4 === 0 && (index - 2) % 2 === 0) ||
                                  ((index + 1) % 4 === 0 &&
                                    (index - 1) % 2 === 0)),
                              "col-16": true,
                            })}
                          >
                            <ErrorBoundary>
                              <TeaserProject
                                item={item}
                                pagerFullPage={
                                  this.props.content.fieldPagerAufVollseiten
                                    ? this.props.content.id
                                    : false
                                }
                              />
                            </ErrorBoundary>
                          </div>
                        );
                      case "veranstaltung":
                        return <TeaserEvent item={item} />;
                      default:
                        return null;
                    }
                  })()}
                </React.Fragment>
              ))}
          </div>
        </div>

        {!this.props.nodes.loading &&
          this.props.nodes.entityQuery.items.length >
            this.props.content.fieldElementeProSeite &&
          this.props.content.fieldPagerVerwenden && (
            <div className="container">
              <div className="row">
                <div className="col-16">
                  <div className="pagination">
                    <ErrorBoundary>
                      <ReactPaginate
                        onPageChange={this.handlePageClick}
                        pageCount={Math.ceil(
                          this.state.itemsMatching.length /
                            this.props.content.fieldElementeProSeite
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousLabel={"Vorherige Seite"}
                        nextLabel={"Nächste Seite"}
                        previousClassName="btn btn-primary previous"
                        nextClassName="btn btn-primary next"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphExtendedTeaserOverview.propTypes = {
  /**
   * The filters to use. Can be any fieldName of the items.
   */
  filterCloudFilters: PropTypes.arrayOf(PropTypes.string),
  /**
   * The labels to use for the filters. If not set, nothing will be displayed.
   */
  filterCloudLabels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Set to true, if only one filter at a time should be active.  If set to
   * false, multiple filters can be active at the same time.
   */
  filterCloudFilterSingle: PropTypes.bool,
  /**
   * How should multiple filters be applied? AND or OR condition?
   */
  filterCloudMultipleFilterCondition: PropTypes.oneOf(["AND", "OR"]),
  /**
   * Set to true, if empty results should be prevented. (Filters that would lead
   * to an empty result, are hidden)
   */
  filterCloudPreventEmptyResult: PropTypes.bool,
  content: PropTypes.shape({
    id: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entityBundle: PropTypes.oneOf(["filtertext_text", "filtertext_filter"]),
        fieldFilterTextText: PropTypes.string,
        fieldFilter: PropTypes.shape({
          label: PropTypes.string,
          id: PropTypes.string,
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
        fieldFilterTextText: PropTypes.string,
        fieldFilterMultiple: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
          })
        ),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
  }),
  skipQuery: PropTypes.bool,
};

/* TODO: Create own detail-page-shared-general-components for extended teaser paragraphs and add own
 *   queries for each component to prevent this chaos.
 * TODO: Refactor all teaserNodeQueries - work with fragments and just make
 *  the conditions and sorting unique for the queries. */
export default connect(mapStateToProps)(
  compose(
    graphql(teaserNodeQueryFilterTag, {
      name: "nodes",
      skip: (props) =>
        !props.content.fieldTypExtended ||
        props.content.fieldManuelleSortierung ||
        props.content.fieldTypExtended === "veranstaltung" ||
        props.skipQuery,
      options: (props) => ({
        variables: {
          limit: 1000,
          type: [props.content.fieldTypExtended],
          tag: props.content.fieldSchlagwort.map((item) =>
            item.targetId.toString()
          ),
          filterTagEnabled: props.content.fieldSchlagwort.length > 0,
        },
      }),
    }),
    graphql(teaserNodeQueryEventTagFiltered, {
      name: "nodes",
      skip: (props) =>
        props.content.fieldTypExtended !== "veranstaltung" ||
        props.content.fieldManuelleSortierung ||
        props.skipQuery,
      options: (props) => ({
        variables: {
          date: moment().format("YYYY-MM-DD"),
          limit: 1000,
          type: [props.content.fieldTypExtended],
          tags: props.content.fieldSchlagwort.map((item) =>
            item.targetId.toString()
          ),
          filterTagEnabled: props.content.fieldSchlagwort.length > 0,
        },
      }),
    })
  )(ParagraphExtendedTeaserOverview)
);
