import React, { useState, useEffect, useRef } from "react";
import {createPortal} from "react-dom";
import SpotifyButtonIdle from "@js/participants/components/spotify-widget/spotify-button-idle";
import SpotifyButtonListen from "@js/participants/components/spotify-widget/spotify-button-listen";
import CustomAnimation from "@general-components/custom-animation";

const SpotifyWidget = ({ content }) => {
  const [spotifyPlaying, setSpotifyPlaying] = useState(false);
  const [spotifyType, setSpotifyType] = useState(false);

  if (content?.fieldSpotify?.uri?.path) {

    const embedControllerRef = useRef(null);

    /* Currently (28.05.24) The Artist URL is structured in the following way:https://open.spotify.com/artist/7rsxf6syscULrGwh2aD0fY
  *  1) https://open.spotify.com/artist/3Fl31gc0mEUC2H0JWL1vic?si=JMgAH8XeSfm8dIwn7gkQxA
  *  We do not need the query param, since we only need the artist id. So we will strip everything except the artist id:
  *  3Fl31gc0mEUC2H0JWL1vic
  */

    // Function to extract the artist ID from a Spotify URL
    const extractSpotifyData = (url) => {
      // Define the regex pattern to match the artist ID in the URL
      const regexArtist = /artist\/([a-zA-Z0-9]+)/,
        regexAlbum = /album\/([a-zA-Z0-9]+)/,
        regexEpisode = /episode\/([a-zA-Z0-9]+)/;

      // Apply the regex to the URL and store the result in 'tch'
      let match = url.match(regexArtist),
        type = "artist";

      if (!match) {
        match = url.match(regexAlbum);
        type = "album";

        if (!match) {
          match = url.match(regexEpisode);
          type = "episode";
        }
      }

      // If a match is found, return the captured artist ID; otherwise, return null
      return {
        id: match ? match[1] : null,
        type: type
      };
    }

    const spotifyData = extractSpotifyData(content.fieldSpotify?.uri.path);

    /*
    * Using the Spotify API-Functionality to embed an iframe.
    * Users only need to insert the Artists Spotify-Profile-Link. We will strip the ID out of the url and insert it into the given iFrame Boilerplate.
    * Functionality Infos can be found here: https://developer.spotify.com/documentation/embeds/tutorials/using-the-iframe-api
    */

    if (spotifyData) {

      useEffect(() => {
        // Ensure the Spotify IFrame API script is loaded
        const script = document?.createElement('script');
        script.src = "https://open.spotify.com/embed-podcast/iframe-api/v1";
        script.async = true;
        document?.body.appendChild(script);

        // Define the onSpotifyIframeApiReady function
        window.onSpotifyIframeApiReady = (IFrameAPI) => {
          const element = document?.getElementById('embed-iframe');
          const options = {
            uri: `spotify:${spotifyData.type}:${spotifyData.id}` // Use the URI from the content prop
          };
          const callback = (EmbedController) => {
            // initialize EmbedController
            embedControllerRef.current = EmbedController;
          };
          IFrameAPI.createController(element, options, callback);
        };

        // Cleanup script and window function on unmount
        return () => {
          document?.body.removeChild(script);
          if (window.onSpotifyIframeApiReady) {
            delete window.onSpotifyIframeApiReady;
          }
        };
      }, [content.fieldSpotify.uri]);

      const togglePlay = () => {
        if (embedControllerRef.current) {
          embedControllerRef.current.togglePlay();
          setSpotifyPlaying(!spotifyPlaying);
        }
      }

      return (
        <div className="spotify-widget">
          <div id="embed-iframe"/>
          {typeof window !== 'undefined' && document?.querySelector(".detail-page-intro .title-wrapper h1") &&
            <>
              {createPortal(
                <button
                  className="toggle-spotify"
                  onClick={() => togglePlay()}
                >
                  <CustomAnimation
                    isVisible={spotifyPlaying}
                    type={"appear"}
                  >
                    <SpotifyButtonListen/>
                  </CustomAnimation>
                  <CustomAnimation
                    isVisible={!spotifyPlaying}
                    type={"appear"}
                  >
                    <SpotifyButtonIdle/>
                  </CustomAnimation>
                </button>,
                document?.querySelector(".detail-page-intro .title-wrapper h1")
              )}
            </>
          }
        </div>
      )
    }
  }
};

export default SpotifyWidget;
