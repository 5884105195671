import React, { Component } from "react";
import PropTypes from "prop-types";
import { teaserActPropTypes } from "../../../../teaser-base/act/teaser-act";
import TeaserActMasked from "../../../../teaser-base/act/teaser-act-masked";

/**
 * A module representing a text paragraph.
 */
const BreakBanner = ({type, content}) => {
  return (
    <div className={`break-banner ${type}`} aria-disabled={"true"}>
      <div className="container highlights">
        {type === "conference" &&
          content.fieldHeading && (
            <h2 className="break-headline">
              {content.fieldHeading}
            </h2>
          )}
        <div className="highlighted-participants">
          {content.fieldSelectedParticipants &&
            content.fieldSelectedParticipants.map(
              (participant, index) => (
                <React.Fragment key={index}>
                  <TeaserActMasked
                    item={participant.entity}
                    index={index}
                  />
                </React.Fragment>
              )
            )}
        </div>
      </div>
    </div>
  );
}

BreakBanner.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldSelectedParticipants: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserActPropTypes,
      })
    ),
  }).isRequired,
  type: PropTypes.oneOf(["conference", "break"]).isRequired,
};

export default BreakBanner;