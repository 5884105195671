import React from "react";

function SpotifyButtonIdle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="51.003"
      version="1.1"
      viewBox="0 0 12.965 13.495"
    >
      <g transform="translate(-107.436 -118.193)">
        <g fill="none" transform="matrix(.26458 0 0 .26458 107.413 117.945)">
          <path
            fill="#000"
            fillRule="evenodd"
            d="M49.06 36.714l.028.021-.025-.025zm0 0l-9.037-6.843.171-28.102c0-.595-.512-.992-.95-.769L18.996 13.862h-.098L2.89 1.744.965 4.346l12.573 9.516L39.999 33.91l7.14 5.402zM.088 32.45V19.811c0-3.123 2.363-5.626 5.36-5.873l34.527 26.119-.073 11.077c-.024.57-.512.967-.975.744L19.045 39.016h-12.5c-3.558 0-6.457-2.949-6.457-6.567z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SpotifyButtonIdle;
