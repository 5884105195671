import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";

import EditButton from "../../../backend/edit-button";
import Image from "../../image/image";
import BrandLogo from "@nodes/brand/brand-logo";

const TeaserBrand = ({ item, index, simpleVersion }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const location = useLocation();

  const isBig = index === 3 || index === 4 || index === 8;

  const teaserClassNames = classNames({
    "node node-teaser teaser-brand col-16": true,
    "col-md-8 big": isBig && !simpleVersion,
    "col-md-4 small": !isBig && !simpleVersion,
    "col-lg-custom small": simpleVersion,
    "with-logo": item.fieldImage,
  });

  return (
    <article className={teaserClassNames} data-index={index}>
      <EditButton
        adminApp={adminApp}
        entityId={item.id}
        destinationRoute={location.pathname}
      />
      <Link className="flex-wrap" to={`#${item.url?.path.substring(1)}`}>
        <div className="image-logo-wrapper">
          {item.fieldImage && (
            <div className="teaser-image">
              <Image
                data={item.fieldImage.fieldMediaImage}
                nodeTitle={item.title}
                style={item.fieldImage.fieldMediaImage.style}
              />
            </div>
          )}
          <BrandLogo item={item} />
        </div>

        <div className="infos">
          {item.label && <h3>{item.label}</h3>}
          <div className="toggle-indicator">
            <div className="icon plus" />
          </div>
        </div>
      </Link>
    </article>
  );
};

export const TeaserBrandPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserBrand.propTypes = {
  item: TeaserBrandPropTypes,
};

export default TeaserBrand;
