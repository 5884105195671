import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphFactsAndFigures = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-facts-and-figures": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <div className="facts-and-figures-element main">
              <div className="value headline-xl">
                {content.fieldParagraphs[0].fieldValue}
              </div>
              <div className="label">
                {content.fieldParagraphs[0].fieldLabel}
              </div>
            </div>
          </div>
          <div className="d-block d-md-none w-100" />
          {content.fieldParagraphs.length > 1 && (
            <>
              <div className="col col-md-8 small">
                {content.fieldParagraphs
                  .slice(1, content.fieldParagraphs.length)
                  .map((item, index) => (
                    <div className="facts-and-figures-element d-table d-md-block" key={index}>
                      <div className="value headline-l">{item.fieldValue}</div>
                      <div className="label">{item.fieldLabel}</div>
                    </div>
                  ))}
              </div>
              <div className="d-block d-md-none w-100" />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphFactsAndFigures.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldParagraphs: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLabel: PropTypes.string.isRequired,
        fieldValue: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default ParagraphFactsAndFigures;
