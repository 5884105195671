import React, { Component } from "react";
import PropTypes from "prop-types";

import { graphql } from "@apollo/client/react/hoc";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

import TeaserQueryCloudContent from "./queries/teaser-query-cloud-content.graphql";
import CloudDetailOverlay from "./components/cloud/cloud-detail-overlay";
import ListView from "./components/list/list-view";
import StoryContainer from "./components/cloud/story-container";
import { activeCloudDetailAction, viewModeAction } from "./cloud-view-action";
import LoadingIndicator from "../../../loading-indicator";
import { Close } from "@carbon/icons-react";

const mapStateToProps = (reduxStore) => ({
  activeCloudDetail: reduxStore.contentCloud.activeCloudDetail,
});

class ContentCloud extends Component {
  container = React.createRef();
  searchElement = React.createRef();

  state = {
    viewMode: "cloud",
    showSearch: false,
  };

  toggleViewMode = (type) => {
    window.scrollTo(0, 0);
    this.setState({ viewMode: type });
    this.props.dispatch(viewModeAction(type));
  };

  scaleStoryElements = () => {
    setTimeout(() => {
      // @todo use ref.
      document
        .querySelectorAll(".cloud-teaser .teaser-wrapper")
        .forEach((item) => item.classList.add("scaled"));
    }, 1000);
  };

  scrollListener = (elements) => {
    if (elements.length > 0) {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (st >= window.innerHeight) {
        elements[0].classList.add("slide-in-first");
      } else {
        elements[0].classList.remove("slide-in-first");
      }

      if (st >= window.innerHeight * 1.5) {
        elements[0].classList.add("slide-in-second");
      } else {
        elements[0].classList.remove("slide-in-second");
      }
    }
  };

  handleClickOutside(event) {
    if (this.searchElement && this.searchElement.current != null) {
      if (!this.searchElement.current.contains(event.target)) {
        this.setState({ showSearch: false });
      }
    }
  }

  showSearch = () => {
    this.setState({ showSearch: true });

    const InputFilterElement = document.getElementsByName("text-title");

    InputFilterElement[0].focus();
  };

  closeSearch = () => {
    this.setState({ showSearch: false });
  };

  componentDidMount() {
    const dialogElements = document.querySelectorAll(".dialogue-wrapper");

    window.addEventListener("scroll", () =>
      this.scrollListener(dialogElements)
    );
    window.addEventListener("mousedown", (event) =>
      this.handleClickOutside(event)
    );

    if (this.container.current != null) {
      /* Scale Items for Start*/
      this.scaleStoryElements();
    }
  }

  render() {
    const { error, loading, nodeQuery } = this.props.nodes;

    if (loading) {
      return <LoadingIndicator />;
    }

    if (error) {
      console.log(error);
    }

    let entities = null;

    if (nodeQuery && !loading && !error) {
      entities = nodeQuery.entities;
    }

    let amountPerWrapper = 5;

    console.log(this.props);

    return (
      <div className="paragraph paragraph-content-cloud">
        <div className="content-wrap">
          <div className="header">
            <div className="wrapper">
              <div className="function" id="header-function">
                <div className="view-chooser item">
                  <span
                    className={classNames({
                      "cloud-icon": true,
                      active: this.state.viewMode === "cloud",
                    })}
                    onClick={() => this.toggleViewMode("cloud")}
                  >
                    cloud
                  </span>
                  <span
                    className={classNames({
                      "list-icon": true,
                      active: this.state.viewMode === "list",
                    })}
                    onClick={() => this.toggleViewMode("list")}
                  >
                    list
                  </span>
                </div>
              </div>
            </div>
          </div>

          {this.state.viewMode === "cloud" ? (
            <div
              ref={this.container}
              className={`container cloud-view ${
                this.props.activeCloudDetail ? "detail-opened" : ""
              }`}
              id="cloud-view-draggable"
            >
              <div className="cloud-detail-scroll-container">
                {this.props.activeCloudDetail && (
                  <CloudDetailOverlay content={this.props.activeCloudDetail} />
                )}
              </div>
              <StoryContainer
                entities={entities.slice(0, amountPerWrapper)}
                scaleStoryElements={this.scaleStoryElements}
              />
              <>
                {[...Array(Math.ceil(entities.length / amountPerWrapper))].map(
                  (time, i) => {
                    return (
                      <LazyLoad key={i} offset={100}>
                        <StoryContainer
                          entities={entities.slice(
                            (i + 1) * amountPerWrapper,
                            (i + 2) * amountPerWrapper
                          )}
                          scaleStoryElements={this.scaleStoryElements}
                        />
                      </LazyLoad>
                    );
                  }
                )}
              </>
            </div>
          ) : (
            <>
              <div className="list-view">
                <ListView entities={entities} />
                {this.props.activeCloudDetail && (
                  <div
                    className="cloud-detail-go-back"
                    onClick={() =>
                      this.props.dispatch(activeCloudDetailAction(null))
                    }
                    style={{ opacity: 1, zIndex: 150, pointerEvents: "all" }}
                  >
                    <Close size={32} color="black" />
                  </div>
                )}
              </div>
              <div className="cloud-detail-scroll-container">
                {this.props.activeCloudDetail && (
                  <CloudDetailOverlay content={this.props.activeCloudDetail} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export const ContentCloudNodePropTypes = {
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  entityLabel: PropTypes.string,
  fieldTeasertext: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldModules: PropTypes.array,
};

ContentCloud.propTypes = {
  activeCloudDetail: PropTypes.shape(ContentCloudNodePropTypes),
  nodes: PropTypes.object.isRequired,
  content: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default withTranslation()(
  graphql(TeaserQueryCloudContent, {
    name: "nodes",
  })(connect(mapStateToProps)(ContentCloud))
);
