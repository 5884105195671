import React from "react";
import WheelchairIcon from "../../assets/ui/wheelchair.svg";
import PropTypes from 'prop-types';


const AccessibilityWrapper = ({ children, accessibilityProperty }) => {
  return (
    <div
      className={`icon ${accessibilityProperty.fieldTaxonomyFrontendId}`}
      key={accessibilityProperty.name}
    >
      {children}
    </div>
  );
};

AccessibilityWrapper.propTypes = {
  children: PropTypes.node,
  accessibilityProperty: PropTypes.object,
}

const AccessibilityIcons = ({ content }) => {
  if (content) {
    return (
      <div className="accessibility-icons">
        {content.map((accessibilityProperty, i) => (
          <React.Fragment key={i}>
            {(() => {
              switch (accessibilityProperty.fieldTaxonomyFrontendId) {
                case "wheelchair": {
                  return (
                    <AccessibilityWrapper
                      accessibilityProperty={accessibilityProperty}
                    >
                      <img src={WheelchairIcon} alt={"Wheelchair Icon"} />
                    </AccessibilityWrapper>
                  );
                }
                default:
                  return false;
              }
            })()}
          </React.Fragment>
        ))}
      </div>
    );
  }
};

AccessibilityIcons.propTypes = {
  content: PropTypes.array,
};

export default AccessibilityIcons;
