import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TopicClickSuggestion from "./click-suggestion-topic";
import Link from "@general-components/link/link";

const ClickSuggestionsTopics = ({ content }) => {

  const sectionClassNames = classNames({
    "paragraph paragraph-click-suggestions-topics": true,
  });

  return (
    <section
      className={sectionClassNames}
    >
      <div className="container">
        <div className="row">
          <div className="col-16 col-lg-14 offset-lg-1 links">
            <ul className="ctas">
              {content.fieldTopicsClickSuggestions && content.fieldTopicsClickSuggestions.map(
                (linkContent, index) => (
                  <li key={index}>
                    <Link link={linkContent?.fieldLink}>
                      <TopicClickSuggestion content={linkContent} />
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

ClickSuggestionsTopics.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldTopicsClickSuggestions: PropTypes.arrayOf(
      PropTypes.shape({
        fieldImage: PropTypes.object,
        fieldLink: PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.shape({
            routed: PropTypes.bool,
            path: PropTypes.string,
          }),
        })
      })
    ),
  }),
};

export default ClickSuggestionsTopics;
