import React from "react";
import { PropTypes } from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

export const customAnimationSettings = {
  initial: { opacity: 0, filter: "blur(100px)" },
  animate: { opacity: 1, filter: "blur(0px)" },
  exit: { opacity: 0, filter: "blur(100px)" },
  transition: { duration: 0.3 },
};

const CustomAnimation = ({ children, type, isVisible }) => {
  return (
    <AnimatePresence>
      {isVisible && children && (
        <motion.div
          className="custom-animation-wrapper"
          {...customAnimationSettings}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

CustomAnimation.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default CustomAnimation;
