import React from "react";
import {FormattedMessage} from "react-intl";

const LookingFor = ({content}) => {

  if (content.fieldLookingFor || content.fieldLfBookingTerritory || content.fieldLfManagementTerritory) {
    return (
      <div className="looking-for">
         <span className="label body-xs">
            <FormattedMessage id="lookingfor" />:
         </span>
        {content.fieldLfBookingTerritory &&
          <div className="booking">
            <span className="what body-xs">
               <FormattedMessage id="booking" />:
             </span>
            {content.fieldLfBookingTerritory &&
              <span className="where body-xs">
                {content.fieldLfBookingTerritory}
              </span>
            }
          </div>
        }
        {content.fieldLfLabelTerritory &&
          <div className="music-label">
            <span className="what body-xs">
               <FormattedMessage id="label" />:
             </span>
            {content.fieldLfLabelTerritory &&
              <span className="where body-xs">
                {content.fieldLfLabelTerritory}
              </span>
            }
          </div>
        }
        {content.fieldLfManagementTerritory &&
          <div className="management">
            <span className="what body-xs">
               <FormattedMessage id="management" />:
             </span>
            {content.fieldLfManagementTerritory &&
              <span className="where body-xs">
                {content.fieldLfManagementTerritory}
              </span>
            }
          </div>
        }
        {content.fieldLfFreeLabel && content.fieldLfFreeTerritory &&
          <div className="main">
            <span className="what body-xs">
               {content.fieldLfFreeLabel}:
             </span>
            {content.fieldLfFreeTerritory &&
              <span className="where body-xs">
                {content.fieldLfFreeTerritory}
              </span>
            }
          </div>
        }
      </div>
    );
  } else {
    return false;
  }
};

export default LookingFor;
