import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import moment from "moment";
import {FormattedMessage} from "react-intl";

import PlayIcon from "../../../../../assets/ui/play_black.svg";
import PauseIcon from "../../../../../assets/ui/pause_black.svg";

const ParagraphAudio = ({ content }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [prevVolume, setPrevVolume] = useState(1);

  const player = useRef();

  const timeUpdateListener = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const getTime = (time) => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      );
    }

    return null;
  };

  const goBack = (seconds) => {
    setCurrentTime((prev) => {
      let newTime = 0;
      if (prev - seconds > 0) {
        newTime = prev - seconds;
      }
      player.current.currentTime = newTime;
      return newTime;
    });
  };

  const play = () => {
    player.current.play();
  };

  const pause = () => {
    player.current.pause();
  };

  const volumeHandler = (e) => {
    setVolume(e.target.value);
    player.current.volume = e.target.value;
  };

  const toggleMute = () => {
    setVolume((prev) => {
      if (prev > 0) {
        setPrevVolume(prev);
        player.current.volume = 0;
        return 0;
      } else {
        player.current.volume = prevVolume;
        return prevVolume;
      }
    });
  };

  useEffect(() => {
    const audioPlayer = player.current;
    setDuration(audioPlayer?.duration);
    // event listener if currentTime changed
    audioPlayer.addEventListener("timeupdate", (e) => timeUpdateListener(e));

    // update time display when metadata has loaded
    audioPlayer.addEventListener("loadedmetadata", (e) =>
      timeUpdateListener(e)
    );

    // remove event listeners on unmount
    return () => {
      if (audioPlayer) {
        audioPlayer.removeEventListener("timeupdate", (e) =>
          timeUpdateListener(e)
        );
        audioPlayer.removeEventListener("loadedmetadata", (e) =>
          timeUpdateListener(e)
        );
      }
    };
  }, [player]);

  const sectionClassNames = classNames({
    'paragraph paragraph-audio': true,
    'with-image': content && content.fieldImage,
    'without-image': content && !content.fieldImage,
    'without-text': !content.fieldText
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      <div className="container">
        <div className="row">
          {(content.fieldHeading || content.fieldSubheadline) &&
            <div className={`col-16 headline-wrapper ${content.fieldImage ? '' : 'col-lg-8'}`}>
              {content.fieldHeading &&
                <h2 className={"headline"}>{content.fieldHeading}</h2>
              }
              {content.fieldSubheadline &&
                <h3 className={"subline"}>{content.fieldSubheadline}</h3>
              }
            </div>
          }
          {content.fieldImage &&
            <div className={`col-16 col-lg-8 image-section`}>
              <div className={"image-wrapper"}>
                <Image data={content.fieldImage.fieldMediaImage} />
              </div>
            </div>
          }
          <div className={`col-16 col-lg-8 audio-section`}>
            <div className="height-wrapper">
              <div className="inner-wrapper">
                <div className={"dashed-corner top left"} />
                <div className={"dashed-corner top right"} />
                <div className={"dashed-corner bottom left"} />
                <div className={"dashed-corner bottom right"} />
                <div className="file-info">
                  {(content.fieldSubline || content.fieldPublishedOn) &&
                    <div className="infos">
                      <div className="date">
                        {content.fieldPublishedOn &&
                          <h4>
                            {moment
                              .utc(content.fieldPublishedOn.value)
                              .local()
                              .format("DD.MM.YYYY")}
                          </h4>
                        }
                      </div>
                      <div className="subline">
                        {content.fieldSubline &&
                          <h4>{content.fieldSubline}</h4>
                        }
                      </div>
                    </div>
                  }
                  {content.fieldTitleAudio &&
                    <h3>{content.fieldTitleAudio}</h3>
                  }
                </div>
                {content.fieldAudioLink?.uri?.path &&
                  <audio
                    src={content.fieldAudioLink.uri.path}
                    aria-controls="audio-controls"
                    ref={player}
                  />
                }
                <div className="audio-controls">
                  <div className="button">
                    {player?.current?.paused ? (
                      <button
                        tabIndex={0}
                        role="button"
                        aria-label={"play"}
                        className="audio-toggle"
                        onClick={play}
                      >
                        <div className={"sr-only"}>
                          <FormattedMessage id={"play"} />
                        </div>
                        <img src={PlayIcon} alt={"play icon"}/>
                      </button>
                    ) : (
                      <button
                        tabIndex={0}
                        role="button"
                        aria-label={"pause"}
                        className="audio-toggle"
                        onClick={pause}
                      >
                        <div className={"sr-only"}>
                          <FormattedMessage id={"pause"} />
                        </div>
                        <img
                          className="download-icon"
                          src={PauseIcon}
                          alt={"pause icon"}
                        />
                      </button>
                    )}
                  </div>
                  <div className="bar-wrapper">
                    <div className={`progress-bar ${currentTime === 0 ? 'paused' : 'playing'}`}>
                      <div className="bar">
                        <div
                          className="fill"
                          style={{
                            width: `${(100 / duration) * currentTime}%`,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="time">
                    <div className="current">
                      {currentTime ? (
                        <span className="current-time">
                          {getTime(currentTime)} min
                        </span>
                      ) : (
                        <span className="current-time">
                          0:00 min
                        </span>
                      )}
                      {duration && <React.Fragment> / {getTime(duration)}</React.Fragment>}
                    </div>
                  </div>
                </div>
              </div>
              {Boolean(content.fieldText) &&
                <div className="text-wrapper">
                  {content.fieldText &&
                    <div
                      className="text allow-scrolling"
                      tabIndex={0}
                      dangerouslySetInnerHTML={{ __html: content.fieldText }}
                    />
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAudio.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldFile: PropTypes.shape({
      name: PropTypes.string,
      fieldDescription: PropTypes.string,
      fieldPreviewImage: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default ParagraphAudio;
