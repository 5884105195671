import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../../teaser-base/event/teaser-event";
import teaserNodeQueryEventTagFiltered from "../../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";

const ParagraphTeaserListEvents = ({ content }) => {
  // get automatically filtered nodes
  const { data } = useQuery(teaserNodeQueryEventTagFiltered, {
    skip: content.fieldMode === "manual",
    variables: {
      limit: 6,
      type: [content.fieldContentType],
      tags: content.fieldTags.map((item) => item.id.toString()),
      filterTagEnabled: !!content.fieldTags.length,
    },
  });

  const nodes =
    content.fieldMode === "auto"
      ? data?.entityQuery?.items || []
      : content.fieldNodes;

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-list": true,
    [`paragraph-teaser-list-${content.fieldLimit}`]: true,
    [`paragraph-teaser-list-${content.fieldContentType}`]: true,
  });

  return (
    <section
      className={sectionClassNames}
      data-darkmode={content.fieldDarkMode}
    >
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row justify-content-center">
          <ErrorBoundary>
            {!!nodes.length &&
              nodes.map((item, i) => <TeaserEvent key={i} item={item} ammount={nodes?.length}/>)}
          </ErrorBoundary>
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserListEvents.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldLimit: PropTypes.number,
    fieldContentType: PropTypes.oneOf(["event"]),
    fieldTags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    fieldNodes: PropTypes.arrayOf(teaserEventPropTypes),
    fieldMode: PropTypes.oneOf(["auto", "manual"]),
  }),
};

export default ParagraphTeaserListEvents;
