import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Create the Page Transition context.
export const PageTransitionContext = React.createContext({});

// The PageTransitionProvider makes the context store accessible to children.
const PageTransitionProvider = ({ children, content }) => {
  const [isLoading, setIsLoading] = useState(true);

  const setMinHeight = () => {
    //document.documentElement?.style.setProperty('--min-height', `${document.querySelector(".main-page-content > *")?.clientHeight}px`);
  }

  // The context value provides all necessary values and functions.
  const value = {
    ...content,
    isLoading,
    setIsLoading,
    setMinHeight
  };

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    /* @todo: Setting the style var as min-height is a workaround. By now the Page Transition is having an glitch when the user scrolled further than 100vh, because the newly loaded site is by default only 100vh high.
    *  The best way would be to delay the Route change till the new page is fully loaded, but by now this does not work.
    */
    if (!isLoading) {
      const timeout = setTimeout(() => {
        setMinHeight();
      }, 1500);

      // clear timeout on component unmount
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [isLoading]);



  return (
    <PageTransitionContext.Provider value={value}>
      {children}
    </PageTransitionContext.Provider>
  );
};

PageTransitionProvider.propTypes = {
  children: PropTypes.node,
};

export default PageTransitionProvider;
