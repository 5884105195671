import React from "react";
import { SocialMedia } from "./components/social-media";
import { Menu } from "@general-components/navigation/menu";
import RBXAnimation  from "../general-components/rbx-animation/rbx-animation";
import PropTypes from "prop-types";
import AccessibilityIcon from "../../assets/ui/accessibility-logo-white.svg";
import FooterPartners from "@js/footer/components/footer-partners";
import Image from "./../general-components/image/image";
import LazyLoad from "react-lazyload";

const Footer = ({ footerConfig, socialMediaConfig }) => {

  return (
    <div className="container">
      {footerConfig && (
        <div className="row">
          <div className={"col-16 col-lg-8 footer logo"}>
            {footerConfig.fieldFooterLogo ? (
              <Image
                data={footerConfig.fieldFooterLogo.fieldMediaImage}
                className="footer-logo"
              />
            ) : (
              <LazyLoad offset={500}>
                <RBXAnimation className="loading-animation" />
              </LazyLoad>
            )}
          </div>
          <div className="col-16 col-lg-8 footer links">
            <div className="footer-menu row">
              {!!footerConfig.fieldFooterMenu?.links.length && (
                <div className="col-16 col-lg-8">
                  <Menu
                    menuTree={footerConfig.fieldFooterMenu}
                    ariaLabel={"footer menu"}
                  />
                </div>
              )}

              <div className="col-16 col-lg-8">
                <img
                  src={AccessibilityIcon}
                  alt={"Button: Infos zur Barrierearmut"}
                  className="accessibility-icon"
                />
                <Menu
                  menuName={"ACCESSIBILITY_MENU"}
                  ariaLabel={"assessibility menu"}
                />
              </div>
            </div>

            {socialMediaConfig && (
              <div className="row footer-social-media">
                <div className="col-16">
                  <h3 className="headline">
                    Socials
                  </h3>
                </div>
                <SocialMedia content={socialMediaConfig} />
              </div>
            )}


            <FooterPartners content={footerConfig}/>
          </div>
        </div>
      )}
    </div>
  );
};

Footer.propTypes = {
  footerConfig: PropTypes.object,
  socialMediaConfig: PropTypes.object
};

export default Footer;
