import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { InlineNotification } from "carbon-components-react";
import { connect } from "react-redux";
import { Navigate, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import queryString from "query-string";

import { authenticationLoginAction } from "./redux-authentication";
import ExecutionEnvironment from "exenv";
import { tokenExpired } from "./util";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class LoginForm extends Component {
  state = {
    loading: false,
    error: "",
    success: false,
    loginQuery: queryString.parse(this.props.location.search),
  };

  handleSubmit = (values, actions) => {
    this.setState({
      loading: true,
      error: "",
    });

    axios
      .post(
        `/api/v1/login`,
        {
          name: values.email,
          pass: values.password,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ success: true });

        setTimeout(() => {
          this.props.dispatch(authenticationLoginAction(response.data));
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.error,
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  render() {
    if (
      this.props.auth.userLoginData.logout_token &&
      ExecutionEnvironment.canUseDOM &&
      this.props.auth.userLoginData.temp_pass
    ) {
      return <Navigate to="/intern/reset-pass/temp" />;
    }

    if (
      this.props.auth.userLoginData.logout_token &&
      !tokenExpired(
        this.props.auth.userLoginData.access_token,
        this.props.auth.userLoginData.jwt.exp
      ) &&
      ExecutionEnvironment.canUseDOM
    ) {
      return (
        <Navigate
          to={
            this.state.loginQuery.destination
              ? this.state.loginQuery.destination
              : "/intern/intern-willkommen"
          }
        />
      );
    }

    return (
      <div className="container">
        <div className="login-wrapper row">
          <div className="col-12 col-md-6 offset-md-3">
            {this.state.success && (
              <InlineNotification
                kind="success"
                title="Anmeldung erfolgreich!"
                subtitle="Sie werden automatisch weitergeleitet."
              />
            )}
            {!this.state.success && this.state.error !== "" && (
              <InlineNotification
                kind="error"
                title="Authentication Error"
                subtitle={this.state.error}
              />
            )}
            <h2>Login</h2>
            <Formik
              onSubmit={(values, actions) => {
                this.handleSubmit(values, actions);
              }}
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  //.email()
                  .required("E-Mail ist ein Pflichtfeld."),
                password: yup
                  .string()
                  .required("Passwort ist eine Pflichtfeld"),
              })}
              className="form-container"
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">E-Mail</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="E-Mail"
                  />
                  <ErrorMessage name="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Passwort</label>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Passwort"
                  />
                  <ErrorMessage name="password" />
                </div>
                <div className="form-group d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  <button type="button" className="btn btn-link">
                    <Link to="/intern/forgot-password">
                      Passwort vergessen?
                    </Link>
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(LoginForm);
