import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

import Image from "../../image/image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import { useLocation } from "react-router-dom";


/**
 * @todo Rename. This is the person teaser with modal on click.
 * @todo Implement viewMode property for all invocations.
 */
const TeaserPersonOverview = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const toggleOverlay = () => {
    setIsOpen((prev) => {
      const newState = !prev;

      if (newState) {
        document.querySelector("html").style.overflow = "hidden";
      } else {
        document.querySelector("html").style.overflow = "visible";
      }

      return newState;
    });
  };

  return (
    <>
      <article
        className="node node-teaser teaser-person teaser-person-overview"
        data-testid="teaser-person"
      >
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location.pathname}
        />
        <div className="flex-wrap" onClick={toggleOverlay}>
          {item.fieldImageRawField?.first && (
            <Image
              data={item.fieldImageRawField.first.entity.fieldMediaImage}
              nodeTitle={item.title}
            />
          )}
          <h3>{item.title}</h3>

          {item.fieldEmail && (
            <a className="mail" href={`mailto:${item.fieldEmail}`}>
              {item.fieldEmail}
            </a>
          )}

          {item.fieldPhone && (
            <a
              className="phone"
              href={`tel:${item.fieldPhone
                .replace(/ /g, "")
                .replace(/\([\s\S]*?\)/g, "")}`}
            >
              {item.fieldPhone}
            </a>
          )}
        </div>
      </article>
      {isOpen && (
        <div className="person-overlay-bg">
          <div className="person-overlay">
            <div className="container">
              <TeaserPersonOverlay item={item} toggleOverlay={toggleOverlay} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
};

export default TeaserPersonOverview;
