import React from "react";
import PropTypes from "prop-types";
import {restHostBackend} from "@js/config";

const BrandLogo = ({ item }) => {
  return (
    <div className="logo-wrapper">
      {item?.fieldLogo ? (
        <img
          src={`${restHostBackend}${item.fieldLogo.fieldMediaImage.entity.uriRawField.first.url}`}
          alt={`Logo: ${item.label}`}
          className="logo"
        />
      ) : (
        <div className="logo-fallback">
          <span className={"headline-s"}>{item?.label}</span>
        </div>
      )}
    </div>
  );
};

export const BrandLogoPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

BrandLogo.propTypes = {
  item: BrandLogoPropTypes
};

export default BrandLogo;