// React and Hooks.
import React, { useState, createContext } from "react";
import { useQuery } from "@apollo/client";
import { useLocalStorage, useSessionStorage } from "react-use";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

// Queries.
import QueryExtendedTeaserView from "../query-overview.graphql";

// Create the overview context.
export const OverviewContext = createContext({});

// Default filter values.
export const defaultFilters = {
  combine: "",
  format: [],
  spoken_language: [],
  event_type: [],
  weekday: [],
  branch: [],
  accessibility: [],
  genre: [],
  mood: [],
  participant_category: [],
};

/**
 * Overview Provider.
 * This provider manages the state of the overview, manages its main logic and provides the necessary
 * values and functions to the children/consumers.
 * The following operations are performed here:
 * - Load or initialize the current sort and filters from the URL search params.
 * - Load or initialize the current page, page mode, nodes, grouped nodes, metadata from the local storage.
 * - Perform the main nodes query.
 * - Reset the nodes, page, and scroll position.
 */
const OverviewProvider = ({ children, content }) => {
  /**
   * useSearchParams hook to perform search query operations.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Load the current page mode and metadata from the local storage.
   */
  const [pageMode, setPageMode] = useSessionStorage("ov:mode", "endless-scroll");
  const [metaData, setMetaData] = useState({
    totalRows: 1,
    perPage: 1,
  });

  /**
   * Extract the sort field and direction from the sort string.
   */
  const [sortField, sortDirection] = (searchParams.get("sort") || "field_date-ASC").split("-");

  // Set View ID Name by content type given
  let viewIdName = content.fieldContentType;

  // Participants will never show all Participants, they will be shown by type. So ask if Participant Type is given and use it
  if (content.fieldParticipantType) {
    viewIdName = content.fieldParticipantType;
  }

  // Events will need further distinction
  switch (content.entityBundle) {
    case "overview_timetable": {
      viewIdName = content.fieldViewType;
    }
  }

  const [currentViewId, setCurrentViewId] = useState(viewIdName);

  const type = content.fieldContentType ? [content.fieldContentType] : null;
  const viewId = `overview_${currentViewId}`;

  /**
   * Build the query variables object for the main nodes query.
   */
  const queryVariables = {
    viewId,
    type,
    page: 0,
    combine: searchParams.get("combine") || defaultFilters.combine,
    format: searchParams.getAll("format"),
    spoken_language: searchParams.getAll("spoken_language"),
    event_type: searchParams.getAll("event_type"),
    weekday: searchParams.getAll("weekday"),
    branch: searchParams.getAll("branch"),
    accessibility: searchParams.getAll("accessibility"),
    genre: searchParams.getAll("genre"),
    mood: searchParams.getAll("mood"),
    participantCategory: searchParams.getAll("participant_category"),
    sortField,
    sortDirection,
  };

  if (viewId === "overview_news") {
    queryVariables.news_type = [content.fieldNewsType];
  }
  /**
   * Main nodes query.
   * The nodes are fetched based on the current view, filters, sort, and page.
   * This is skipped if the query hash stored in the local storage won't change.
   */
  const { data, loading, error, fetchMore } = useQuery(
    QueryExtendedTeaserView,
    {
      variables: { ...queryVariables },
      notifyOnNetworkStatusChange: true,
      // Update the metadata when the query is completed.
      onCompleted: (data) => {
        setMetaData({
          totalRows: data.entityById.executable.execute.total_rows,
          perPage: data.entityById.executable.pager.perPage,
        });
      },
    }
  );

  const nodes = data?.entityById.executable.execute.rows;
  /**
   * The context value provides all necessary values and functions.
   */
  const value = {
    ...content,
    metaData,
    currentViewId,
    setCurrentViewId,
    searchParams,
    setSearchParams,
    pageMode,
    setPageMode,
    nodes,
    fetchMore,
    loading,
    error,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    fieldContentType: PropTypes.oneOf([
      "article",
      "event",
      "news",
      "project",
      "venue",
      "participant",
    ]),
    fieldParticipantType: PropTypes.oneOf(["act", "speaker", "venue"]),
    fieldViewType: PropTypes.array,
    entityBundle: PropTypes.oneOf([
      "overview_acts",
      "overview_speakers",
      "overview_venues",
      "overview_timetable",
    ]),
    fieldNewsType: PropTypes.oneOf(["news", "press"]),
  }),
};

export default OverviewProvider;
