import React from "react";
import PropTypes from "prop-types";

const Role = ({ role }) => {
  if (!role?.length) return;
  if (role.filter((item) => !!item).length === 0) return;

  role = role.map((item) => item.name).join(", ");

  return <div className="role">{role}</div>;
};

Role.propTypes = {
  role: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default Role;
