import React from "react";
import { PropTypes } from "prop-types";
import { Form, Formik } from "formik";
import { useQuery } from "@apollo/client";
import QueryExtendedTeaserViewFilters from "../query-filters.graphql";
import { motion } from "framer-motion";

// Filters.
import StringFilter from "./string";
import TaxonomyFilter from "./taxonomy";
import ListFilter from "./list";

import useOverviewContext from "../../store/use-overview-context";
import ErrorBoundary from "@js/error-boundary";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FormattedMessage } from "react-intl";
import ButtonReset from "./button-reset";
import AutoSubmit from "../system/auto-submit";
import { customAnimationSettings } from "@general-components/custom-animation";
import _ from "lodash";

/**
 * Renders a set of filters for a specific view.
 */
const Filters = ({ onFilterClose }) => {
  const { currentViewId, searchParams, setSearchParams, metaData } =
    useOverviewContext();

  //useLockBodyScroll();

  const viewId = `overview_${currentViewId}`;

  // Get exposed filters.
  const { data } = useQuery(QueryExtendedTeaserViewFilters, {
    variables: {
      viewId,
    },
    fetchPolicy: "cache-first",
  });

  // Get active exposed filters and exclude the bundle filter.
  const defaultBundleFilter = (item) => {
    return item.pluginId === "bundle" && item.field === "type";
  };

  // Filters to skip as they are exposed but used in a different place.
  const skipFilters = ["field_weekday_value", "field_news_type_target_id"];

  const filters = data?.entityById.executable.filters
    ?.filter((filter) => filter.isExposed && !defaultBundleFilter(filter))
    ?.filter((filter) => skipFilters.indexOf(filter.options.id) === -1);

  // Get initialValues for views exposed form.
  const initialValues = {};
  filters?.forEach((filter) => {
    const identifier = filter.options.expose.identifier;
    initialValues[identifier] = filter.options.value;

    // Strings are single values, others are arrays.
    if (filter.pluginId === "string") {
      initialValues[identifier] =
        searchParams.get(identifier) || filter.options.value;
    } else {
      initialValues[identifier] =
        searchParams.getAll(identifier) || filter.options.value;
    }
  });

  // Handler for filter changes.
  // This function will be called when the form is submitted.
  const filterChangeHandler = (values) => {
    // Create new search params object to change the values.
    let newSearchParams = new URLSearchParams(searchParams.entries());

    // Iterate over the values and set them in the search params.
    for (const [key, value] of Object.entries(values)) {
      if (typeof value === "string") {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
        value.forEach((item) => {
          newSearchParams.append(key, item);
        });
      }
      const isObjectAndEmpty = (typeof value === "object" && value.filter(Boolean).length === 0);
      const isStringAndEmpty = (typeof value === "string" && value === "");
      if (isObjectAndEmpty || isStringAndEmpty) {
        newSearchParams.delete(key);
      }
    }
    // Only update the search params if they have changed.
    if (
      !_.isEqual(
        Array.from(newSearchParams.entries()),
        Array.from(searchParams.entries())
      )
    ) {
      setSearchParams(newSearchParams);
      window.scrollTo({
        top: document.querySelector(".paragraph-extended-teaser-view")
          ?.offsetTop,
        behavior: "smooth",
      });
    }
  };

  // If no filters are exposed, return null.
  if (!filters?.length) return null;

  return (
    <motion.div className="position-wrapper" {...customAnimationSettings}>
      <div className="click-overlay" onClick={onFilterClose} />
      <Formik initialValues={initialValues} onSubmit={filterChangeHandler}>
        <Form>
          <div className="filters">
            <div className="custom-modal">
              <Accordion
                allowZeroExpanded={true}
                allowMultipleExpanded={false}
                preExpanded={["combine"]}
              >
                <AutoSubmit />
                {filters.map((item, index) => {
                  return (
                    <div className={`filter ${item.pluginId}`} key={index}>
                      <ErrorBoundary>
                        <AccordionItem
                          uuid={item.options.expose.identifier}
                          key={item.options.expose.identifier}
                          data-uuid={item.options.expose.identifier}
                        >
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {item.options.expose.label && (
                                <label htmlFor={item.options.expose.identifier}>
                                  {item.options.expose.label}
                                </label>
                              )}
                              <div className="toggle-indicator">
                                <div className="icon plus" />
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div
                              className={"collapse-content d-flex flex-wrap"}
                            >
                              {(() => {
                                switch (item.pluginId) {
                                  case "string":
                                  case "combine":
                                    return <StringFilter item={item} />;
                                  case "taxonomy_index_tid":
                                    return <TaxonomyFilter item={item} />;
                                  case "list_field":
                                    return <ListFilter item={item} />;
                                  default:
                                    return null;
                                }
                              })()}
                            </div>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </ErrorBoundary>
                    </div>
                  );
                })}
              </Accordion>
              <div className="button-wrapper flex-row">
                <ButtonReset />
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={onFilterClose}
                >
                  {metaData.totalRows} <FormattedMessage id={"show_results"} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </motion.div>
  );
};

Filters.displayName = "Filters";

Filters.propTypes = {
  active: PropTypes.bool,
  onFilterClose: PropTypes.func,
};

export default Filters;
