import React from "react";
import {FormattedMessage} from "react-intl";
import TeaserSpeakerShort from "@teasers/speaker/teaser-speaker-short";

const ParticipatingPersons = ({ content }) => {

  if (content?.fieldSpeakersRawField || content?.fieldModeratorsRawField) {
    return(
      <div className="participating-persons">
        <div className="container">
          <div className="row">
            {content?.fieldSpeakersRawField?.list?.length > 0 &&
              <>
                <div className="col-16 label headline-m">
                  <FormattedMessage id={"speaker"}/>
                </div>
                {content?.fieldSpeakersRawField?.list.map((speaker, speakerIndex) => (
                    <div
                      className="col-16 col-md-8 teaser-wrapper"
                      key={speakerIndex}
                    >
                      <TeaserSpeakerShort
                        item={speaker.entity}
                      />
                    </div>
                  ))}
              </>
            }
            {content?.fieldModeratorsRawField?.list?.length > 0 &&
              <>
                <div className="col-16 label headline-m">
                  <FormattedMessage id={"moderator"}/>
                </div>
                {content?.fieldModeratorsRawField?.list.map((moderator, moderatorIndex) => (
                    <div
                      className="col-16 col-md-8 teaser-wrapper"
                      key={moderatorIndex}
                    >
                      <TeaserSpeakerShort
                        item={moderator.entity}
                      />
                    </div>
                  ))}
              </>
            }
          </div>
        </div>
      </div>
    );
  }
};

export default ParticipatingPersons;
