import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import BusinessWrapper from "@js/participants/components/business-wrapper";
import { FormattedMessage } from "react-intl";

const TeaserSpeakerShort = ({ item, index }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);
  const location = useLocation();

  return (
    <article
      className="node node-teaser teaser-speaker short"
      data-index={index}
    >
      <div className="mask-wrapper">
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location.pathname}
        />
        <div className="row">
          <div className="col-16 col-md-8 image">
            {item.fieldImage && (
              <Image
                data={item.fieldImage.fieldMediaImage}
                nodeTitle={item.title}
              />
            )}
          </div>
          <div className="col-16 col-md-8 infos">
            <div className="title-wrapper">
              {item.title && (
                <h3 className="name headline-s">
                  {item.title}
                  {item.fieldCountry && <> ({item.fieldCountry?.value})</>}
                </h3>
              )}
            </div>
            <BusinessWrapper content={item} />
            <Link
              className="action-element body-xs"
              to={item.url?.path || item.path.alias}
            >
              <FormattedMessage id="learn_more" />
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

export const teaserSpeakerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldImage: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserSpeakerShort.propTypes = {
  item: teaserSpeakerPropTypes,
};

export default TeaserSpeakerShort;
