import React from "react";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import getEncodedIcalUrl from "../lib/get-encoded-ical-url";
import SaveTheDateIcon from "@/assets/ui/save-the-date.svg";

export const AddToCalendar = ({ content, appearance }) => {

  let date = appearance.fieldDateRawField?.first;

  const eventPassed = moment().diff(moment(date.value)) > 0;

  let venue = "";

  if (appearance?.fieldVenue) {
    venue = appearance.fieldVenue.title;
  }

  if (appearance?.fieldVenue?.fieldAddress?.addressLine1) {
    venue = `${venue} - ${appearance?.fieldVenue.fieldAddress.addressLine1}`;
  }

  if (appearance?.fieldVenue?.fieldAddress?.addressLine2) {
    venue = `${venue} ${appearance?.fieldVenue.fieldAddress.addressLine2}`;
  }

  if (appearance?.fieldVenue?.fieldAddress?.postalCode) {
    venue = `${venue}, ${appearance?.fieldVenue.fieldAddress.postalCode}`;
  }

  if (appearance?.fieldVenue?.fieldAddress?.locality) {
    venue = `${venue}, ${appearance?.fieldVenue.fieldAddress?.locality}`;
  }

  let event = {
    title: content?.label,
    description: content.fieldText,
    location: venue,
    datetime: date
  }

  if (!eventPassed) {
    return(
      <div className="add-to-calendar action-element body-xs">
        <a
          className="d-flex flex-row"
          href={getEncodedIcalUrl(event)}
        >
          <img src={SaveTheDateIcon} alt={"Save the date icon"}/>
          <FormattedMessage id={"event.remember"}/>
        </a>
      </div>
    );
  }
};
