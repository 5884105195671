import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphQuote = ({ content }) => {
  const { fieldImage = null, fieldText, fieldSource, fieldHighlighted, fieldDarkMode } = content;

  const sectionClassNames = classNames({
    "paragraph paragraph-quote": true,
    "with-image": fieldImage,
    "no-image": !fieldImage,
    "highlighted ": fieldHighlighted,
    standard: !fieldHighlighted
  });

  return (
    <section className={sectionClassNames} data-darkmode={fieldDarkMode}>
      <div className="container">
        <div className="row">
          {fieldImage !== null && (
            <div className="col-16 col-lg-7 image-col">
              <ErrorBoundary>
                <Image
                  data={fieldImage.fieldMediaImage}
                  className="quote-image"
                />
              </ErrorBoundary>
            </div>
          )}
          <div
            className={classNames({
              "col-16 quote-col": true,
              "col-lg-9": fieldImage,
            })}
          >
            <blockquote className={"text"}>

              <div className={"dashed-corner top left"} />
              <div className={"dashed-corner top right"} />
              <div className={"dashed-corner bottom left"} />
              <div className={"dashed-corner bottom right"} />

              <div className="text">{`« ${fieldText.replace(/<\/?[^>]+(>|$)/g, "")} »`}</div>
              <cite>{fieldSource}</cite>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphQuote.propTypes = {
  viewMode: PropTypes.string,
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.object,
    }),
    fieldText: PropTypes.string.isRequired,
    fieldSource: PropTypes.string.isRequired,
  }),
};

ParagraphQuote.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphQuote;
