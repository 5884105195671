import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoRBF from "../../assets/logos/rbf-logo.svg";

import { defaultLocale } from "../app";

const LogoButton = () => {
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);
  const Logo = () => <img src={LogoRBF} className={"logo"} alt={"RBF Logo"}/>;

  let link = "/";

  if (currentLanguage !== "und" && defaultLocale !== currentLanguage) {
    link = `/${currentLanguage}`;
  }

  return (
    <Link className="site-logo-button" to={link}>
      <Logo />
    </Link>
  );
};

export default LogoButton;
